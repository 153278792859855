import {Auth} from '@/api/repository/authRepository.js'
import {RepositoryFactory} from '@/api/factory/repositoryFactory'
const Resource = RepositoryFactory.get('resource')
import s3Uploader from '@/plugins/s3Uploader.js'
import auth from '@/store/modules/auth'
import get from 'lodash-es/get'
import mimeTypes from 'mime-types'
const namespaced = true
import vendor from '@/store/modules/vendor'

const state = {
  videos: {},
  video: {},
  count: 0,
  lastEvaluatedKey: null,
  resourceStatistic: {},
  resourceStatisticOfMonth: [],
  editedIndex: -1,
  filesEdited: [],
  pendingVideo: [],
  minimumWindowStatus: false,
  isNewUpload: false,
  isUploadDone: false,
  videoExisted: [],
  initLoading: true,
  itemVideosPerPage: 20,
  isSearching: false,
  countVideoCancel: 0,
  currentPage: 1,
  videosIsDownloading: [],
}
const actions = {
  async createHLSVideo({commit}, {file, relation = {}, onProgress, onCancel = () => {}}) {
    const duration = get(file, 'duration', null)
    const resolution = get(file, 'resolution', null)
    const video = await Resource.create(
      generateVideo(file, relation, {
        link: null,
        subType: 'video',
        type: 'video',
        provider: 'VSTORAGE',
        html: null,
        duration,
        resolution,
        isCDNW: true
      })
    )
    const vendorId = vendor.state.vendor.vendorId
    const resourceId = video.data.id
    const extension = file.name.split('.').pop()

    const link = `raw/${vendorId}/${resourceId}/source.${extension}`
    await s3Uploader.uploadFile({
      file,
      link,
      onProgress: ({percent}) => {
        onProgress({percent: Math.min(99, percent)})
      },
      urlPostfix: '/resource/signed-url-video',
      onCancel: (cancelExcecutor) => onCancel(cancelExcecutor, resourceId),
    })
    await Auth.requestTranscodingVideo({resourceId, vendorId, extension, provider: 'cdnw', resolution}, auth.state.auth.accessToken)
    const videoData = {...video.data, status: 'queued'}
    onProgress({percent: 100})
    commit('addVideo', videoData)
    return videoData
  },
  async fetchVideos({commit}, params = {}) {
    const res = await Resource.fetch({
      ...params,
      type: 'video',
    })
    commit('setVideos', res.data || [])
    return res.data
  },
  async countVideos({commit}, params = {}) {
    const res = await Resource.count({
      ...params,
      type: 'video',
    })
    commit('setCount', res.data || 0)
  },
  async updateVideo({commit}, {id, ...video}) {
    const res = await Resource.update(id, video)
    return commit('setVideo', get(res, 'data.Attributes', {}))
  },
  async removeVideo({commit}, id) {
    await Resource.remove(id)
    return commit('removeVideo', id)
  },
  async setVideo({commit}, video) {
    return commit('setVideoData', video)
  },
  async addVideo({commit}, video) {
    return commit('setVideo', video)
  },
  async resourceStatistic({commit}, params = {}) {
    const res = await Resource.fetchStatistic({...params})
    commit('setResourceStatistic', res.data || {})
    return res.data
  },
  async resourceStatisticOfMonth({commit}, params = {}) {
    const res = await Resource.fetchStatisticOfMonth({
      ...params,
    })
    commit('setResourceStatisticOfMonth', res.data || [])
    return res.data
  },
  async fetchOneResourceIndependent(_, id) {
    const res = await Resource.fetchOne(id)
    return res.data || {}
  },
  async updateVideoIndependent(_, {id, ...params}) {
    const res = await Resource.update(id, params)
    return res.data || {}
  },
  async getLinkDownload(_, {id, params}) {
    const res = await Resource.getDownloadUrl({id, params})
    return res.data
  },
  setEditedIndex({commit}, index) {
    commit('setEditedIndex', index)
  },
  removeEditedIndex({commit}) {
    commit('removeEditedIndex')
  },
  setFileEdited({commit}, files) {
    commit('setFileEdited', files)
  },
  addPendingVideo({commit}, video) {
    commit('addPendingVideo', video)
  },
  updateVideoAfterUpload({commit}, video) {
    commit('updateVideoAfterUpload', video)
  },
  setMinimumWindowStatus({commit}, status) {
    commit('setMinimumWindowStatus', status)
  },
  setUploadStatus({commit}, status) {
    commit('setUploadStatus', status)
  },
  setVideoExisted({commit}, videos) {
    commit('setVideoExisted', videos)
  },
  setInitLoading({commit}, status) {
    commit('setInitLoading', status)
  },
  setItemVideosPerPage({commit}, numberItem) {
    commit('setItemVideosPerPage', numberItem)
  },
  setSearchStatus({commit}, status) {
    commit('setSearchStatus', status)
  },
  setCountVideoCancel({commit}, count) {
    commit('setCountVideoCancel', count)
  },
  setNewUploadStatus({commit}, status) {
    commit('setNewUploadStatus', status)
  },
  setCurrentPage({commit}, page) {
    commit('setCurrentPage', page)
  },
  addVideosIsDownloading({commit}, videoId) {
    commit('addVideosIsDownloading', videoId)
  },
  removeVideoDownloading({commit}, videoId) {
    commit('removeVideoDownloading', videoId)
  },
}

const mutations = {
  setVideo(state, video) {
    return (state.videos = {
      ...state.videos,
      [video.id]: video,
    })
  },
  setCount(state, count) {
    return (state.count = count)
  },
  addVideo(state, video) {
    state.count = state.count + 1
    return (state.videos = {
      [video.id]: video,
      ...state.videos,
    })
  },
  setVideoData(state, video) {
    return (state.video = video)
  },
  appendVideos(state, videos) {
    return (state.videos = {
      ...state.videos,
      ...videos.reduce((acc, cur) => ({...acc, [cur.id]: cur}), {}),
    })
  },
  setVideos(state, videos) {
    return (state.videos = videos.reduce((acc, cur) => ({...acc, [cur.id]: cur}), {}))
  },

  removeVideo(state, id) {
    delete state.videos[id]
    state.videos = {...state.videos}
  },
  setLastEvaluatedKey(state, key) {
    state.lastEvaluatedKey = key
  },
  setResourceStatistic(state, data) {
    state.resourceStatistic = data
  },
  setResourceStatisticOfMonth(state, data) {
    state.resourceStatisticOfMonth = data
  },
  setEditedIndex(state, index) {
    state.editedIndex = index
  },
  removeEditedIndex(state) {
    state.editedIndex = -1
  },
  setFileEdited(state, files) {
    state.filesEdited = files
  },
  addPendingVideo(state, video) {
    if (Array.isArray(video) && video.length !== 0) {
      state.pendingVideo = [...state.pendingVideo, ...video]
    } else {
      state.pendingVideo.push(video)
    }
  },
  updateVideoAfterUpload(state, video) {
    let arrayId = Object.keys(state.videos)
    let index = arrayId.findIndex((val) => val === video.id)
    if (index > -1) {
      state.videos[video.id] = video
    } else {
      return
    }
  },
  setMinimumWindowStatus(state, status) {
    state.minimumWindowStatus = status
  },
  setUploadStatus(state, status) {
    state.isUploadDone = status
  },
  setVideoExisted(state, videos) {
    state.videoExisted = videos
  },
  setInitLoading(state, status) {
    state.initLoading = status
  },
  setItemVideosPerPage(state, numberItem) {
    state.itemVideosPerPage = numberItem
  },
  setSearchStatus(state, status) {
    state.isSearching = status
  },
  setCountVideoCancel(state, count) {
    state.countVideoCancel = count
  },
  setNewUploadStatus(state, status) {
    state.isNewUpload = status
  },
  setCurrentPage(state, page) {
    state.currentPage = page
  },
  addVideosIsDownloading(state, videoId) {
    state.videosIsDownloading = [...state.videosIsDownloading, videoId]
  },
  removeVideoDownloading(state, videoId) {
    let index = state.videosIsDownloading.findIndex((val) => val === videoId)
    if (index > -1) {
      state.videosIsDownloading.splice(index, 1)
    }
  },
}
const getters = {
  videos: (state) => {
    return Object.values(state.videos)
  },
  count: (state) => {
    return state.count
  },
  video: (state) => {
    return state.video
  },
  resourceStatistic: (state) => {
    return state.resourceStatistic
  },
  getResourceStatisticOfMonth: (state) => {
    return state.resourceStatisticOfMonth
  },
  getEditedIndex: (state) => state.editedIndex,
  getFileEdited: (state) => state.filesEdited,
  getPendingVideo: (state) => state.pendingVideo,
  getMinimumWindowStatus: (state) => state.minimumWindowStatus,
  getUploadStatus: (state) => state.isUploadDone,
  getVideoExisted: (state) => state.videoExisted,
  getInitLoading: (state) => state.initLoading,
  getItemVideosPerPage: (state) => state.itemVideosPerPage,
  getSearchStatus: (state) => state.isSearching,
  getCountVideoCancel: (state) => state.countVideoCancel,
  getNewUploadStatus: (state) => state.isNewUpload,
  getPage: (state) => state.currentPage,
  getVideoIsDownloading: (state) => state.videosIsDownloading,
}

const generateVideo = (file, relation = {}, {link, subType, provider, html, bucket, duration, resolution, isCDNW = false}) => {
  return {
    title: file.name,
    size: file.size,
    dataType: 'resource',
    createdBy: auth.state.auth.id,
    link,
    html,
    provider,
    duration,
    type: 'video',
    origin: {
      link,
      size: file.size,
      type: 'video',
      html,
      mimeType: mimeTypes.lookup(file.name),
      bucket,
      resolution,
      isCDNW
    },
    subType,
    relation,
  }
}
export default {
  namespaced,
  state,
  actions,
  mutations,
  getters,
}
