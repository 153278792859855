import {RepositoryFactory} from '@/api/factory/repositoryFactory'
import get from 'lodash-es/get'
const Question = RepositoryFactory.get('question')
import questionUploader from '@/plugins/questionUploader.js'

const state = {
  questions: [],
  count: 0,
  bankQuestions: [],
  question: {},
  pageText: '',
  itemsPerPage: 12,
  currentPage: 0,
}

const actions = {
  async createQuestion(_, data) {
    const question = await Question.create(data)
    return question.data
  },
  createOrUpdateQuestion({dispatch}, {questionId, data}) {
    if (questionId) {
      return dispatch('updateQuestion', {id: questionId, question: data})
    } else {
      return dispatch('createQuestion', data)
    }
  },
  async fetchOneQuestion(_, id) {
    const res = await Question.fetchOne(id)
    return res.data
  },
  async updateQuestion(_, {id, question}) {
    const res = await Question.update(id, question)
    return res.data
  },
  async removeQuestion(_, id) {
    await Question.remove(id)
  },
  async fetchQuestions({commit}, params = {}) {
    const res = await Question.fetch({
      ...params,
    })
    commit('setQuestions', res.data || [])
    const questionsNoGroup = res.data.filter((q) => q.type !== 'group')
    commit('setCount', questionsNoGroup.length || 0)
    return res.data
  },
  resetQuestions({state}) {
    state.questions = []
    state.count = 0
  },
  //#region question bank flow - refactor these after deadline
  async fetchQuestionCount({commit}, params = {}) {
    const res = await Question.count(params)
    commit('setCount', res.data || 0)
  },
  async fetchBankQuestions({commit}, params = {}) {
    const res = await Question.fetch({
      ...params,
    })
    commit('setBankQuestions', res.data || [])
    return res.data
  },
  async createBankQuestion({commit, rootState}, data) {
    const question = await Question.create(data)
    const allKnowledges = rootState.knowledge.allKnowledges
    if (Object.keys(allKnowledges).length > 0) {
      const questionKnowledges = get(question.data, 'knowledge', [])
      const mappedKnowledgesForQuestion = []
      if (questionKnowledges.length > 0) {
        for (const knowledgeId of questionKnowledges) {
          mappedKnowledgesForQuestion.push(allKnowledges[knowledgeId])
        }
      }
      commit('addOrUpdateBankQuestion', {...(question.data || {}), knowledge: mappedKnowledgesForQuestion})
    } else {
      commit('addOrUpdateBankQuestion', question.data || {})
    }
    return question.data
  },
  async updateBankQuestion({commit}, {id, question}) {
    const {knowledge} = question
    const res = await Question.update(id, {...question, knowledge: knowledge.map((k) => k.id)})
    commit('addOrUpdateBankQuestion', {...(res.data || {}), knowledge})
    return res.data
  },
  async removeBankQuestion({commit}, item) {
    await Question.remove(item._id, {
      vendorId: item.vendorId,
    })
    commit('removeBankQuestion', item)
  },
  //#endregion
  async uploadQuestionViaFile(_, {file, unitId, onProgress, mode}) {
    const res = await questionUploader.uploadDocxToServer({
      file,
      unitId,
      onProgress: ({percent}) => onProgress({percent}),
      mode,
    })
    if (get(res, 'status') === 200) {
      const code = get(res, 'data.code', '200')
      if (code === '200') {
        return {status: 200, content: res.data}
      } else if (code === '2001') {
        return {status: 204, content: res.data}
      } else if (code === '2005') {
        return {status: 203, content: res.data}
      }
    } else {
      return {status: 503}
    }
  },
  setQuestionBank({commit}, question) {
    commit('setQuestionBank', question)
  },
  setItemsPerPage({commit}, itemsPerPage) {
    commit('setItemsPerPage', itemsPerPage)
  },
  requestPageSetting({commit}, page) {
    commit('requestPageSetting', page)
  },
}

const mutations = {
  setQuestion(state, newQuestion) {
    return (state.questions = state.question.map((question) => {
      return question._id === newQuestion._id ? newQuestion : question
    }))
  },
  setQuestions(state, questions) {
    return (state.questions = questions)
  },
  reset(state) {
    state.questions = []
  },
  //#region question bank flow - refactor these after deadline
  setBankQuestions(state, bankQuestions) {
    state.bankQuestions = bankQuestions
  },
  removeBankQuestion(state, item) {
    if (item && item.id) {
      let deleteIndex = state.bankQuestions.findIndex((listItem) => listItem && listItem.id === item.id)
      if (deleteIndex > -1) {
        state.bankQuestions = state.bankQuestions.filter((listItem) => listItem.id !== item.id)
      }
    }
  },
  addOrUpdateBankQuestion(state, item) {
    const exceptTypes = [
      'single-choice-child',
      'multiple-choice-child',
      'fill-blank-child',
      'direct-fill-blank-child',
      'true-false-choice-child',
    ]
    if (item && item.id && !exceptTypes.includes(item.type)) {
      let foundIndex = state.bankQuestions.findIndex((listItem) => listItem && listItem.id === item.id)
      if (foundIndex > -1) {
        state.bankQuestions.splice(foundIndex, 1, item)
      } else {
        state.bankQuestions.unshift(item)
      }
    }
  },
  setCount(state, count) {
    state.count = count
  },
  setQuestionBank(state, question) {
    state.question = question
  },
  setItemsPerPage(state, data) {
    state.itemPerPage = data
  },
  setAttributes(state, obj) {
    state[obj.name] = obj.value
  },
  requestPageSetting(state, page = 1) {
    let pageText = ''
    const itemsLength = state.count
    const pageStart = (page - 1) * state.itemPerPage + 1
    let pageStop = page * state.itemsPerPage
    pageStop = pageStop > itemsLength || pageStop === -1 ? itemsLength : pageStop
    pageText = `${pageStart}-${pageStop} trên ${itemsLength}`
    state.pageText = pageText
  },
}

const getters = {
  getQuestions: (state) => {
    return state.questions
  },
  getQuestionCount: (state) => {
    return state.count
  },
  getBankQuestions: (state) => {
    return state.bankQuestions
  },
  getQuestion: (state) => {
    return state.question
  },
  getPageText: (state) => {
    return state.pageText
  },
  getItemsPerPage: (state) => {
    return state.itemsPerPage
  },
  getCurrentPage: (state) => {
    return state.currentPage
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
