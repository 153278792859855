const COURSE_LIST = {
  TITLE_ONLINE_COURSE: 'Khoá học Online',
  TITLE_OFFLINE_COURSE: 'Khoá học Offline',
  BUTTON_CREATE: 'Tạo khoá học',
  LABEL_FILTER_BY_NAME: 'Tên khoá học',
  PLACEHOLDER_FILTER_BY_NAME: 'Tìm kiếm theo tên khoá học',
  //TABLE HEADER
  TH_NAME: 'Tên khoá học',
  TH_UPDATE_DATE: 'Cập nhật',
  TH_STATUS: 'Trạng thái',
  TH_ACTION: 'Thao tác',
  TEXT_PUBLISED: 'Đã xuất bản',
  TEXT_NOT_PUBLISH: 'Chưa xuất bản',
  TOOLTIP_EDIT_COURSE: 'Chỉnh sửa khóa học',
  //EMPTY DATA
  TITLE_EMPTY_DATA: 'Chưa tồn tại khóa học nào',
  SUBTITLE_EMPTY_DATA: 'Hiện chưa có khóa học nào. Cùng tạo nhanh một khóa học nhé!',
  MSG_GETTING_DATA: 'Đang lấy dữ liệu khóa học ...',
  //CARD COURSE CONTENT
  LABEL_TEACHER_TO_UNDERTAKE: 'Giảng viên phụ trách',
  LABEL_NOT_TEACHER_TO_UNDERTAKE: 'Chưa có giảng viên phụ trách',
  LABEL_PUBLISHED: 'Đã xuất bản',
  LABEL_UNPUBLISH: 'Chưa xuất bản',
  LABEL_NOT_FOUND_RESULT: 'Không tìm thấy kết quả phù hợp!',
  LABEL_FREE: 'Miễn phí',
  LABEL_NOT_SELECT_TOPIC: 'Chưa chọn chủ đề',
  LABEL_TEXT_CONTACT: 'Liên hệ',
  LABEL_NOT_PRICE: 'Chưa có giá',
  LABEL_UNIT_PRICE: 'đ',
  LABEL_UNIT_COURSE: 'khoá',
  TOOLTIP_GIVE_COURSE_TO_HIGHLIGHTS_LIST: 'Đưa khoá học này vào danh sách khoá học nổi bật',
  TOOLTIP_CANCEL_COURSE_GET_OUT_HIGHLIGHTS_LIST: 'Bỏ khoá học này ra ngoài danh sách khoá học nổi bật',
  BUTTON_VIEW: 'Xem thêm khoá học',
}
const FORM_CREATE_NEW_COURSE = {
  TITLE: 'Tạo khoá học mới',
  LABEL_INPUT_NAME: 'Tên khoá học',
  PLACEHOLDER_INPUT_NAME: 'Nhập tên khoá học',
  HINT_INFO: 'Thông tin khóa học luôn có thể thay đổi',
  MSG_CREATE_SUCCESS: 'Tạo khoá học mới thành công !',
  MSG_CREATE_FAIL: 'Tạo khoá học mới thất bại',
}
const COURSE_OVERVIEW = {
  TAB_INFO: 'Thông tin',
  TAB_SCHEDULE_AND_CLASS: 'Lịch và lớp học',
  TAB_LEARNING_CONTENT: 'Nội dung học',
  //TAB
  TAB_BASIC_INFO: 'Thông tin cơ bản',
  TAB_ADVANCE_INFO: 'Thông tin nâng cao',
  TAB_INTERACTION: 'Cài đặt tương tác',
  TAB_THUMBNAIL: 'Ảnh / Video đại diện',
  TAB_PRICING_AND_OWNERSHIP: 'Giá tiền và sở hữu',
  TAB_PUBLISHING: 'Xuất bản',
  TAB_DELETION: 'Xoá khoá học',
  BASIC_INFO: {
    ON_CARD: {
      TITLE: 'Thông tin cơ bản',
      SUBTITLE: 'Thiết lập tên, danh mục và chỉ định giáo viên cho khoá học',
      SUBTITLE_OFFLINE_COURSE: 'Thiết lập tên, danh mục và chỉ định giáo viên, tổng thời lượng cho khoá học',
      LABEL_NAME: 'Tên khoá học (Tối đa 120 kí tự)',
      PLACEHOLDER_INPUT_NAME: 'Nhập tên khoá học',
      LABEL_CATEGORY: 'Danh mục',
      PLACEHOLDER_SELECT_TOPIC: 'Chọn chủ đề',
      BUTTON_CREATE_CATEGORY: 'Tạo thêm danh mục',
      LABEL_ADD_TEACHER: 'Giảng viên chỉ định',
      PLACEHOLDER_SELECT_TEACHER: 'Chọn giảng viên',
      BUTTON_CREATE_TEACHER: 'Tạo thêm giảng viên',
      LABEL_EXPIRY_COURSE: 'Tổng thời lượng',
      PLACEHOLDER_INPUT_EXPIRY_COURSE: 'Nhập thời lượng',
      OPTION_SHOW_TEACHER_FOR_STUDENT: 'Công khai giảng viên chỉ định cho học viên',
      LABEL_SLUG: 'Tên rút gọn',
      PLACEHOLDER_SLUG: 'Nhập tên rút gọn',
    },
    DURATION_SETTING: {
      TITLE: 'Thời lượng',
      SUBTITLE: 'Cung cấp thông tin thời lượng khoá học',
      OPTION_SELF_INPUT: 'Tự nhập',
      OPTION_SYSTEM_AUTO_CACULATE: 'Cho phép tự động tính toán dựa trên các bài giảng',
      LABEL_TIME: 'Tổng thời lượng',
      PLACEHOLDER_TIME: 'Nhập thời lượng',
      MSG_GETTING_DATA_TIME: 'Đang lấy dữ liệu thời lượng ...',
    },
    DESCRIPTION: {
      TITLE: 'Mô tả ngắn gọn',
      SUBTITLE: 'Cung cấp mô tả để giới thiệu nhanh về khoá học tại trang danh sách',
      LABEL_INPUT_SHORT_DESCRIPTION: 'Mô tả ngắn gọn (Độ dài đề nghị: 200 ký tự)',
      PLACEHOLDER_INPUT_DESCRIPTION: 'Nhập mô tả',
      HTML_INPUT_DESCRIPTION: 'Nội dung html',
      CSS_INPUT_DESCRIPTION: 'Nội dung css',
      JS_INPUT_DESCRIPTION: 'Nội dung js',
    },
  },
  ADVANCE_INFO: {
    DESCRIPTION_DETAIL: {
      TITLE: 'Mô tả chi tiết',
      SUBTITLE: ' Cung cấp mô tả để học viên tham khảo và hiểu rõ hơn về khoá học tại trang giới thiệu riêng',
    },
    COURSE_FAKE_RATING: {
      TITLE: 'Đánh giá mặc định',
      SUBTITLE: 'Thiết lập thông tin đánh giá về khoá học',
      CHECKBOX_TURN_ON_SETTING: 'Chọn thiết lập',
      TH_POINT: 'Điểm đánh giá',
      TH_QUANTITY: 'Số lượng đánh giá',
      TEXT_VERY_BAD: 'Rất tệ',
      TEXT_NEED_TO_CHANGE: 'Cần thay đổi',
      TEXT_NOT_GOOD: 'Chưa tốt',
      TEXT_GOOD: 'Tốt',
      TEXT_VERY_GOOD: 'Rất tốt',
      TH_TOTAL_REVIEW: 'Tổng lượt đánh giá',
      TH_AVERAGE_OF_REVIEW: 'Trung bình đánh giá',
    },
    COURSE_FAKE_STUDENT_NUMBER: {
      TITLE: 'Số lượng học sinh mặc định',
      SUBTITLE: 'Thiết lập số lượng học sinh đã tham gia khoá học',
      LABEL_FIRST_NUMBER_STUDENTS: 'Số học viên ban đầu',
      PLACEHOLDER_STUDENT_TO_BUY: 'Nhập số học viên đã mua',
      LABEL_STUDENTS_INCREASED_BY_DAY: 'Số lượng học viên tăng theo ngày',
      PLACEHOLDER_STUDENT_BUY_COURSE: 'Nhập số học sinh đã mua khoá học',
      LABEL_AVAILABLE_DATE: 'Tính từ ngày',
      PLACEHOLDER_ANWSER: 'Nhập câu trả lời',
    },
  },
  //------------COURSE_INTERACTION-----------
  TITLE_INTERACTION: 'Đánh giá',
  SUBTITLE_INTERACTION: 'Cấu hình quy tắc về đánh giá khoá học',
  LABEL_SHOW_COURSE_SETTING: 'Khoá học chỉ hiển thị cho học sinh có sở hữu khoá học này',
  CHECKBOX_CAN_ENABLE_RATE: 'Cho phép học viên có thể viết đánh giá khi đã sở hữu khoá học',
  CHECKBOX_MUST_APPROVE_RATE: 'Chỉ hiển thị các đánh giá đã được duyệt',

  DISPLAY_IMAGE: {
    ON_CARD: {
      TITLE: 'Ảnh đại diện',
      SUBTITLE: 'Cấu hình ảnh hiển thị trên thẻ tại màn hình danh sách. Kích thước khuyên dùng: ',
      TEXT_RATIO: 'tỉ lệ',
    },
    BACKGROUND: {
      TITLE: 'Ảnh bìa',
      SUBTITLE: 'Cấu hình ảnh hiển thị trên đầu trang giới thiệu riêng. Kích thước khuyên dùng: {0}',
      TEXT_IMAGE_UNIT: 'ảnh',
    },
  },

  //DISPLAY_VIDEO_ON_CARD
  TITLE_VIDEO: 'Video giới thiệu',
  SUBTITLE_VIDEO: 'Cấu hình video ngắn giới thiệu về khoá học, xuất hiện khi người dùng bấm vào thẻ khoá học.',
  SUBTITLE_VIDEO_HAS_CAPACITY_LIMIT:
    'Cấu hình video giới thiệu ngắn về lộ trình học. Video sẽ xuất hiện thay vì ảnh đại diện lộ trình học khi click vào thẻ. (Giới hạn dung lượng tải lên là 1.5GB)',
  CHECKBOX_VIDEO_INTRO: 'Chọn thiết lập',
  LABEL_SELECT_VIDEO_FROM_LIBRARY: 'Chọn từ thư viện',
  LIMIT_CAPACITY: 'Giới hạn dung lượng tải lên là',
  LABEL_STATUS_VIDEO: 'Trạng thái video',
  TITLE_VIDEO_THUMBNAIL: 'Ảnh thumbnail cho video',
  SUBTITLE_VIDEO_THUMBNAIL:
    'Kích thước khuyên dùng: {0} (tỉ lệ {1}). Để tối ưu tốc độ tải thumbnail trên video, hãy đảm bảo ảnh có dung lượng càng nhỏ càng tốt (dung lượng tối đa: {2}).',
  MSG_ADD_VIDEO_THUMBNAIL_SUCCESS: 'THêm thumbnail cho video thành công!',

  TEXT_VIDEO_PROCESSED: 'Video đang được xử lý',
  TEXT_READY: 'Đã sẵn sàng',
  TITLE_DRAG_DROP: 'Kéo thả tại đây',
  BUTOTN_SELECT_FILE_IN_MACHINE: 'Chọn Tệp Trên Máy',
  MSG_WAITING_VIDEO_PROCESSED: 'Video đang được xử lý. Vui lòng đợi trong giây lát.',
  MSG_SUPPORT_VIDEO_PROCESSED:
    'Nếu vấn đề vẫn tồn đọng trong thời gian dài, vui lòng liên hệ đội ngũ kỹ thuật để được hỗ trợ.',
  MSG_FILE_NOT_NAME: 'File chưa có tên',
  //------------COURSE_PRICING-----------
  TITLE_PRICING: 'Giá tiền',
  SUBTITLE_PRICING:
    'Cấu hình mức giá hiển thị cho khoá học. Chú ý: Học viên có thể tham gia khoá học mà không phải trả phí nếu được cấp quyền',
  LABEL_PRICE_CONTACT: 'Thiết lập mức giá liên hệ',
  RADIO_PRICE_ALL_COURSE: 'Thiết lập thanh toán một lần',
  TYPE_OF_TIME: {
    TITLE_MONTH: 'Tháng',
    TITLE_DATE: 'Ngày',
    TITLE_WEEK: 'Tuần',
    TITLE_SLOT: 'Buổi',
  },

  //Kiểm tra lại
  RADIO_PRICE_COURSE_HINT: 'Học viên cần thanh toán tổng giá trị khoá học để tham gia',
  LABEL_PRICE: 'Giá tiền',
  LABEL_PRICE_BY_TIME: 'Thiết lập thanh toán theo mốc thời gian',
  LABEL_SELECT_ABOUT_TIME: 'Chọn mốc thời gian thanh toán',
  ABOUT_TIME: 'Khoảng thời gian',
  LABEL_BILL_PRICE: 'Giá tiền',
  //LABEL_PRICE_CONTACT: 'Liên hệ',
  MSG_REMOVE_CLASS_HINT: 'Lớp học bị xoá sẽ không thể khôi phục',

  CHECKBOX_COURSE_FREE: 'Miễn phí',
  HINT_COURSE_FREE: 'Khoá học miễn phí (không cần mua).',
  CHECKBOX_PAY_ONCE_FOR_COURSE: 'Trả tiền một lần',
  HINT_PAY_ONCE_FOR_COURSE: 'Người dùng trả tiền một lần để sở hữu khoá học vĩnh viễn.',
  LABEL_PRICE_OFFICIAL: 'Giá tiền chính thức',
  LABEL_CURRENCY_UNIT: 'VND',
  PLACEHOLDER_PRICE_OFFICIAL: 'Cổng thanh toán yêu cầu giao dịch trên 10.000VNĐ !',
  LABEL_PRICE_AFTER_DISCOUNT: 'Giá tiền trước khi khuyến mãi',
  HINT_PRICE_AFTER_DISCOUNT: 'Bỏ trống nếu không hiển thị',
  CHECKBOX_DISALLOWANCE_PAY: 'Không cho phép mua hoặc vào học miễn phí',
  HINT_DISALLOWANCE_PAY: 'Khoá học chỉ được học bởi nhóm học sinh có gán khoá học.',
  MSG_PRICE_AT_LEAST: 'Giá tiền không được nhỏ hơn',

  //------------COURSE_PUBLISHING-----------
  PUBLISHING: {
    TITLE_STOP_PUBLISH: 'Ngừng xuất bản khoá học này',
    SUBTITLE_STOP_PUBLISH: 'Ngừng xuất bản khoá học và ẩn khỏi các trang danh sách.',
    TITLE_PUBLISH_COURSE: 'Xuất bản khoá học',
    SUBTITLE_PUBLISH:
      'Công khai khoá học này và hiển thị trên các trang danh sách. (Trừ trường hợp khoá học bị giới hạn hiển thị)',
    BUTTON_PUBLISH: 'Xuất bản',
    BUTTON_STOP_PUBLISH: 'Ngừng xuất bản',
    POPUP_CONFIRM_STOP_TITLE: 'Bạn có chắc muốn ngừng xuất bản khóa học này?',
    POPUP_CONFIRM_STOP_MESSAGE:
      'Ngừng xuất bản khoá học và ẩn khỏi các trang danh sách. Chú ý: học viên đang sở hữu khoá học vẫn có thể truy câp được vào khoá học.',
  },
  DELETION: {
    TITLE: 'Xoá khoá học',
    SUBTITLE: 'Tiến hành xoá khoá học. Chú ý: Khóa học bị xoá sẽ không thể khôi phục',
    BUTTON: 'Xoá khoá học',
    POPUP_CONFIRM_TITLE: 'Bạn có chắc muốn xóa khóa học này?',
    POPUP_CONFIRM_MESSAGE:
      'Dữ liệu sau khi xóa không thể khôi phục. Hệ thống sẽ tự động xóa toàn bộ chương và bài học thuộc về khóa học này. Bạn có chắc chắn muốn xóa khóa học này ?',
  },
}
const OFFLINE_COURSE_DETAIL = {
  LINK_RETURN_OFFLINE_COURSE_LIST: 'Danh sách khóa học Offline',
  CLASS: {
    TITLE: 'Lớp',
    LABEL_NEW: 'Lớp học mới',
    BUTTON_ADD: 'Thêm lớp mới',
    MSG_CREATING: 'Đang tạo lớp học mới',
    MSG_CREATE_SUCCESS: 'Tạo khoá học thành công',
    BASIC_INFO: {
      TITLE: 'Thông tin cơ bản',
      LABEL_NAME: 'Tên lớp học',
      PLACEHOLDER_INPUT_NAME: 'Nhập tên lớp học',
      LABEL_DEPARTMENT: 'Cơ sở',
      PLACEHOLDER_INPUT_DEPARTMENT: 'Nhập tên cơ sở',
    },
    SCHEDULE: {
      TITLE: 'Lịch học',
      LABEL_OPENING_CEREMONY: 'Ngày khai giảng',
      RADIO_WEEKLY: 'Lịch lặp lại hàng tuần',
      RADIO_FREE: 'Lịch tự do',
      MSG_CHECK_AGAIN: 'Vui lòng kiểm tra lại Lịch học tự do và lặp lại hàng tuần!',
      BUTTON_ADD: 'Thêm lịch trình',
      PLACEHOLDER_DAY_IN_WEEK: 'Ngày trong tuần',
    },
  },
  DELETION: {
    TITLE: 'Xoá lớp học',
    SUBTITLE: 'Lớp học bị xoá sẽ không thể khôi phục',
    BUTTON: 'Xoá lớp học',
    POPUP_CONFIRM_TITLE: 'Bạn có chắc muốn xóa lớp học này?',
    POPUP_CONFIRM_MESSAGE: 'Lớp học bị xoá sẽ không thể khôi phục',

    MSG_LOADING: 'Đang xoá lớp học ...',
    MSG_SUCCESS: 'Xoá lớp học thành công',
  },
  SYLLABUS: {
    LIST: {
      TITLE_LESSON_UNIT: 'Bài',
      BUTTON_ADD_LESSON: 'Thêm bài học',
    },
    DETAIL: {
      TITLE_LESSON_UNIT: 'Bài',
      INFO: {
        TITLE: 'Thông tin bài học',
        LABEL_LESSON_HEADING: 'Tiêu đề bài học',
        PLACEHOLDER_LESSON_HEADING: 'VD: Bài 1: Mở bài cơ bản',
        LABEL_DESCRIPTION_LESSON: 'Mô tả bài giảng',
        PLACEHOLDER_DESCRIPTION_LESSON: 'VD: Giới thiệu kiến thức cơ bản cho hầu hết học viên...',
      },
      DELETION: {
        TITLE: 'Xoá bài học',
        SUBTITLE: 'Bài học bị xoá sẽ không thể khôi phục',
        BUTTON: 'Xoá bài học',
        POPDUP_CONFIRM_TITLE: 'Bạn có chắc muốn xóa bài học này?',
        POPDUP_CONFIRM_MESSAGE: 'Bài học bị xoá sẽ không thể khôi phục',
      },
    },
  },
  FEE: {
    PLACEHOLDER_FILTER_STUDENT_NAME: 'Tìm kiếm theo tên học viên',
    TH_STUDENT_NAME: 'Tên học viên',
    TH_DEADLINE: 'Hạn cuối',
    TH_DATE_PAYMENT: 'Ngày nộp',
    TH_FEE: 'Học phí (đ)',
    TH_METHOD: 'Phương thức',
    TH_STATUS: 'Tình trạng',
  },
  MSG_UPDATING_LESSON: 'Đang cập nhật bài giảng...',
  MSG_UPDATE_LESSON_SUCCESS: 'Cập nhật bài giảng thành công!',
  DAY_IN_WEEK: {
    TITLE_MONDAY: 'Thứ 2',
    TITLE_TUESDAY: 'Thứ 3',
    TITLE_WEDNESDAY: 'Thứ 4',
    TITLE_THURSDAY: 'Thứ 5',
    TITLE_FRIDAY: 'Thứ 6',
    TITLE_SATURDAY: 'Thứ 7',
    TITLE_SUNDAY: 'Chủ Nhật',
  },
}
const ONLINE_COURSE_DETAIL = {
  TOOLTIP_RETURN_TO_ONLINE_COURSE_LIST: 'Danh sách khóa học Online',
  TAB_INFO: 'Thông tin',
  TAB_LEARNING_CONTENT: 'Nội dung học',
  TAB_RESULT: 'Kết quả',
  TAB_PROGRESS: 'Tiến độ',
  TAB_DISCUSS: 'Thảo luận',
}
//THÔNG TIN KHOÁ HỌC ONLINE
const ONLINE_COURSE_OVERVIEW = {
  //-----------COURSE_INTERACTION-------------
  INTERACTION: {
    VIDEO_FREE: {
      TITLE: 'Trải nghiệm miễn phí',
      SUBTITLE: 'Cấu hình cho phép học viên xem thử một số bài giảng định dạng video miễn phí.',
      BUTTON_LIST: 'Danh sách video xem thử',
      //COURSE_INTERACTION_TRIAL_POPUP
      TRIAL_POPUP: {
        TITLE: 'Danh sách video xem thử',
        LABEL_PICK_LESSON: 'Chọn bài giảng video',
        PLACEHOLER_SELECT_LESSON: 'Vui lòng chọn bài giảng video',
        TEXT_NO_DATA: 'Tất cả bài giảng dạng video đều đã được thêm vào danh sách xem thử',

        TITLE_EMPTY_DATA:
          'Chưa có bài giảng video nào. Vui lòng tạo thêm bài giảng định dạng video để có thể sử dụng chức năng này.',
        BUTTON_CLOSE: 'Đóng',
        TEXT_UNCLEAR: 'Không rõ',
      },
    },
  },
  //COURSE_VIDEO

  TH_VIDEO_LESSON: 'Bài giảng video',
  TH_CHAPTER_TITLE: 'Chương',
  TH_ACTION: 'Thao tác',
  //EMPTY DATA

  //STUDY_ROUTE
  TITLE_STUDY_ROUTE: 'Tiến trình học',
  SUBTITLE_STUDY_ROUTE: 'Cấu hình quy tắc về tiến trình học.',
  CHECKBOX_STUDY_ROUTE_SETTING:
    'Bắt buộc học viên hoàn thành bài giảng hiện tại trước khi chuyển sang bài giảng tiếp theo',

  //SHOW_MY_COURSE_SETTING
  TITLE_SHOW_MY_COURE: 'Giới hạn hiển thị',
  SUBTITLE_SHOW_MY_COURE:
    'Cấu hình để khoá học không xuất hiện tại các trang thông thường và chỉ hiển thị trong một số điều kiện cụ thể',
  CHECKBOX_SHOW_MY_COURE: 'Chỉ hiển thị cho học sinh đã sở hữu khoá học',

  //	EVALUATE: 'Đánh giá',
  TITLE_EVALUATE: 'Đánh giá',
  SUBTITLE_EVALUATE: 'Cấu hình quy tắc về đánh giá khoá học',
  CHECKBOX_EVALUATE_SETTING_ALLOW: 'Cho phép học viên có thể viết đánh giá khi đã sở hữu khoá học',
  CHECKBOX_EVALUATE_SETTING_OBLIGATORY: 'Chỉ hiển thị các đánh giá đã được duyệt',

  //COMMENT_OFF_STUDENT
  TITLE_COMMENT: 'Thảo luận',
  SUBTITLE_COMMENT: 'Cấu hình quy tắc về thảo luận trong khoá học',

  CHECKBOX_STUDENT_COMMENT: 'Cho phép học viên thảo luận, chia sẻ kiến thức tại các bài giảng trong khoá học',
  CHECKBOX_BROWSER_STUDENT_COMMENT: 'Chỉ hiển thị các thảo luận, chia sẻ kiến thức đã được duyệt',
  CHECKBOX_TURN_ON_COMMENT_FACEBOOK: 'Tích hợp bình luận Facebook trên trang giới thiệu riêng',
  LABEL_APPID_CONFIG: 'AppId',
  PLACEHOLDER_APPID_CONFIG: 'Ví dụ: 2221114335145',

  //NOTIFICATION_TO_STUDENT
  TITLE_NOTIFICATION: 'Thông báo - Notifications',
  SUBTITLE_NOTIFICATION: 'Cấu hình quy tắc gửi thông báo đến học viên',
  CHECKBOX_SEND_NOTIFICATION_TO_STUDENT: 'Cho phép gửi thông báo đến học viên khi khoá học được cập nhật',

  //COURSE_PRICING
  TITLE_PRICING_AND_OWNERSHIP: 'Giá tiền và sở hữu',
  SUBTITLE_PRICING_AND_OWNERSHIP:
    'Cấu hình mức giá hiển thị cho khoá học và quy tắc sở hữu. Chú ý: Học viên có thể sở hữu khoá học mà không phải trả phí nếu được cấp quyền.',

  RADIO_LABEL_FREE_TITLE: 'Thiết lập miễn phí',
  RADIO_LABEL_FREE_SUBTITLE: 'Học viên có thể sở hữu mà không cần thanh toán',

  RADIO_LABEL_PAY_TITLE: 'Thiết lập thanh toán một lần',
  RADIO_LABEL_PAY_SUBTITLE: 'Học viên cần thanh toán để sở hữu khoá học',
  LABEL_PRICE_OFFICIAL: 'Mức giá cuối',
  SUFFIX_CURREN_UNIT: 'VND',
  LABEL_AFTER_DISCOUNT: 'Mức giá trước khi khuyến mại',
  HINT_AFTER_DISCOUNT: 'Bỏ trống nếu không có khuyến mại',

  RADIO_LABEL_NOT_ALLOW_BUY_COURSE_TITLE: 'Cấu hình khoá giá tiền',
  RADIO_LABEL_NOT_ALLOW_BUY_COURSE_SUBTITLE:
    'Học viên không thể thanh toán và chỉ sở hữu khoá học nếu có mã kích hoạt hoặc được cấp quyền',
  MSG_SMALLEST_PRICE: 'Giá tiền không được nhỏ hơn {0} đ',
  TEXT_CURRENT_UNIT: 'đ',
}
//NỘI DUNG KHOÁ HỌC ONLINE
const ONLINE_COURSE_CONTENT = {
  TOTAL_SCORE_AND_TIME_PART: {
    TITLE_EDIT_TIME: 'Bảng sửa thời gian',
    TITLE_EDIT_MARK: 'Bảng sửa điểm',
    TITLE_TIME_AND_MARK: 'Thời gian và điểm số',
    LABEL_QUESTION_GROUP: 'Nhóm câu hỏi',
    LABEL_TOTAL_SENTENCE: 'Tổng số câu/điểm',
    LABEL_TOTAL_TIME: 'Tổng thời gian làm bài',
    TEXT_SENTENCE: 'Câu',
    TEXT_MARK: 'điểm',
    TEXT_MINUTE_UNIT: 'phút',
    TEXT_SECOND_UNIT: 'giây',
    TEXT_UNLIMITED: 'Không giới hạn',
    RADIO_UNLIMITED: 'Không giới hạn',
    LABEL_POINT: 'Điểm số',
    RADIO_LIMITED: 'Giới hạn',
    RADIO_LIMITED_BY_PART: 'Giới hạn và tính theo từng phần',
    RADIO_LIMITED_BY_QUESTION: 'Giới hạn và tính theo từng câu',
    RADIO_CACULATOR_BY_TEN_MARK: 'Tính theo thang điểm 10',
    RADIO_CACULATOR_BY_QUESTION_MARK: 'Tính theo tổng điểm câu hỏi',
  },
  DIALOG_SETTING_EXAM: {
    TITLE: 'Cấu hình bài kiểm tra',
    POPUP_CONFIRM_EDIT_EXAM_CONFIG_TITLE: 'Thay đổi cấu hình bài kiểm tra',
    POPUP_CONFIRM_EDIT_EXAM_CONFIG_MESSAGE:
      'Bạn đang tiến hành thay đổi cấu hình bài kiểm tra. Thao tác này có thể làm cho điểm hoặc thời gian của bài kiểm tra không còn như cũ. Bạn vẫn chắc chắn thực hiện thao tác này ?',
    BUTTON_OK: 'Đồng ý',
    PART_SETTING: {
      TITLE: 'Cấu trúc bài kiểm tra',
      RADIO_NO_DISTRIBUTION: 'Không chia phần',
      RADIO_DISTRIBUTED_IN_MULTIPLE_PARTS: 'Được chia thành nhiều phần',
    },
  },
  BUTTON_ADD_NEW_TOPIC: 'Thêm chương mới',
  TITLE_LESSON_NUMBER: 'Bài giảng số {0}',
  MSG_ADD_NEW_LESSON_SUCCESS: 'Thêm bài giảng mới thành công!',
  MSG_ADD_NEW_LESSON_FAIL: 'Thêm bài giảng mới thất bại!',
  LABEL_CHAPTER: 'Chương',
  ENTITY_LESSON: 'Bài giảng',
  LABEL_CHILD_CHAPTER: 'Chương con {0}',

  MSG_ADD_CHAPTER_SUCCESS: 'Thêm chương mới thành công!',
  MSG_ADD_CHAPTER_FAIL: 'Thêm chương mới thất bại!',
  MSG_ADD_CHILD_CHAPTER_SUCCESS: 'Thêm chương con mới thành công!',
  MSG_ADD_CHILD_CHAPTER_FAIL: 'Thêm chương con mới thất bại!',
  //COURSE_SECTION
  LABEL_COURSE_UNIT: 'Khoá',
  BUTTON_ADD_LECTURES: 'Bài giảng',
  BUTTON_ADD_CHILD_CHAPTER: 'Thêm chương con',
  BUTTON_CREATE_LEARNING_AVAILABLE: 'Sao chép',
  TITLE_ADD_NEW: 'Thêm mới',
  TITLE_CREATE_LECTURES: 'Tạo bài giảng có sẵn',
  //EDIT_CHAPTER_TITLE
  TITLE_CHAPTER_INFO: 'Thông tin chương',
  LABEL_TOPIC: 'Tiêu đề chương',
  PLACEHOLDER_TOPIC: 'Nhập chủ đề chương',
  TITLE_SORT_SECTION: 'Sắp xếp chương',
  MSG_SORTING_LESSON: 'Đang sắp xếp lại bài giảng...',
  MSG_CHANGE_ORDER_SORT: 'Thay đổi thứ tự thành công',
  MSG_TEXT_DELETE: 'Xoá',
  MSG_TEXT_DELETE_SUCCESS: 'thành công !',
  POPUP_CONFIRM_DELETE_CHAPTER_TITLE: 'Xoá chương',
  POPUP_CONFIRM_DELETE_CHAPTER_MESSAGE:
    'Dữ liệu sau khi xóa không thể khôi phục. Hệ thống sẽ tự động xóa toàn bộ chương và bài học thuộc về bài học này.Bạn có chắc chắn muốn xóa bài học này ?',

  //EDIT_UNIT_BASIC_INFO
  TITLE_LESSON: 'Thông tin bài giảng',
  LABEL_LESSON_NAME: 'Tên bài giảng',
  PLACEHOLDER_LESSON_NAME: 'Nhập tên bài giảng',
  CHECKBOX_DESCRIPTION_LESSON: 'Mô tả bài giảng',
  CHECKBOX_ATTACH_FILE: 'Tệp đính kèm',
  PLACEHOLDER_PICK_FILE_UPLOAD: 'Chọn ảnh tải lên',

  //COURSE_EDIT_UNIT_DISCUSSION
  TITLE_DISCUSSION: 'Thảo luận',
  CHECKBOX_DISCUSSION_IN_LESSON: 'Cho phép học viên thảo luận trong bài giảng',
  CHECKBOX_ACCEPT_DISCUSSION: 'Bắt buộc duyệt thảo luận cho học viên',

  //COURSE_EDIT_UNIT_TIME_CONDITION
  TITLE_EXPIRED_TIME: 'Thời gian mở và đóng',
  CHECKBOX_TIME_CONDITION: 'Thời gian làm bài',
  PLACEHOLDER_TIME_CONDITION_BY_PART: 'Thời gian làm bài theo phần',
  LABEL_TIME_CONDITION_BY_MINUTE: 'Thời gian làm bài (tính theo phút)',
  PLACEHOLDER_TIME_CONDITION_BY_MINUTE: 'Nhập thời gian',
  LABEL_RANGE_TIME: 'Hẹn giờ mở và đóng cho phép truy cập',
  LABEL_RANGE_TIME_FROM_DATE: 'Ngày bắt đầu',
  LABEL_RANGE_TIME_FROM_HOUR: 'Giờ bắt đầu',
  LABEL_RANGE_TIME_TO_DATE: 'Ngày kết thúc',
  LABEL_RANGE_TIME_TO_HOUR: 'Giờ kết thúc',

  LABEL_SETTING_RESULT_PUBLISH_TIME: 'Hẹn thời gian công bố kết quả',
  LABEL_PUBLISH_DATE: 'Ngày công bố',
  LABEL_PUBLISH_HOURSE: 'Giờ công bố',

  MSG_CHECK_TIME_CONDITION_AGAIN: 'Thời gian truy cập không hợp lệ !',
  MSG_CHECK_START_TIME_CONFITION_AGAIN: 'Thời gian bắt đầu phải trước thời gian kết thúc !',

  //COURSE_EDIT_UNIT_RULES
  TITLE_RULES: 'Nội quy',
  CHECKBOX_SUBMIT_ONCE: 'Chỉ cho phép học viên làm bài 1 lần',
  CHECKBOX_RANKING_HIDDEN: 'Ẩn thứ hạng sau khi làm xong bài',
  CHECKBOX_RESULT_HIDDEN: 'Ẩn điểm và kết quả sau khi làm xong bài',
  CHECKBOX_ANSWER_HIDDEN: 'Ẩn đáp án đúng khi xem kết quả',
  CHECKBOX_ENABLE_DETECT_FOCUSOUT: 'Theo dõi số lần học sinh rời khỏi bài làm',
  CHECKBOX_SCREEN_RATIO: 'Tỉ lệ màn hình 50/50',
  WARNING: 'Cảnh báo',
  WARNING_MESSAGE_MIN_SCORE:
    'Cấu hình điểm tối thiểu mà bạn vừa cài đặt sẽ không áp dụng với những học sinh đã hoàn thành bài trước đây. Vui lòng chú ý theo dõi các trường hợp học sinh đã hoàn thành bài.',
  ERROR_MESSAGE_MIN_SCORE: 'Điểm tối thiểu đang không phù hợp với thang điểm và số điểm của bài kiểm tra.',
  WARNING_MESSAGE_MIN_SCORE_ONLY_ONE:
    'Đây là bài kiểm tra có điểm tối thiếu và học sinh chỉ được làm 01 lần. Nếu học sinh không đạt, sẽ không thể hoàn thành bài này để tới bài tiếp theo. Bạn có chắc chắn muốn tiếp tục không?',

  //COURSE_EDIT_TEST_EXAM_RULES...
  POPUP_CONFIRM_CONFIGURATION_QUESTIONS_TITLE: 'Chuyển cấu hình câu hỏi',
  POPUP_CONFIRM_CONFIGURATION_QUESTION_SUBTITLE: 'Bạn có muốn tiến hành chuyển cấu hình sang',
  POPUP_CONFIRM_SEPARATE_QUESTION: 'chia câu hỏi thành từng phần.',
  POPUP_CONFIRM_MERGE_QUESTION: 'xếp tất cả câu hỏi thành một danh sách.',
  POPUP_CONFIRM_CONFIGURATION_QUESTIONS_MESSAGE: 'Thao tác có thể khiến các câu hỏi bị thay đổi thú tự.',
  BUTTON_AGREE: 'Đồng ý',

  POPUP_CONFIRM_SETTING_TIME_TITLE: 'Chuyển cài đặt thời gian',
  POPUP_CONFIRM_SETTING_TIME_MESSAGE:
    'Bạn có muốn tiến hình chuyển cài đặt thời gian sang theo từng phần không? Bạn sẽ phải cấu hình lại thời gian cho từng phần.',

  LABEL_QUESTION_NOT_SELECT: 'Chưa chọn câu trả lời',
  //COURSE_EDIT_VIDEO_RULES
  TITLE_VIDEO_SETTING: 'Cài đặt bài giảng video',
  LABEL_USING_VIDEO: 'Sử dụng đường dẫn video có sẵn',

  INTERACT_COURSE_CARD_SETTING: {
    TITLE: 'Truy cập trực tiếp vào khoá học',
    TOGGLE_DIRECT_ACCESS_TO_THE_COURSE: 'Truy cập trực tiếp vào khoá học không qua popup',
  },

  //BUTTON
  BUTTON_ADD_QUESTION: 'Thêm câu hỏi',
  IMPORT_QUESTION: {
    TRIGGER_BUTTON: 'Tải lên câu hỏi',
    TRIGGER_OPTION_TEMPLATE: 'Tải xuống file mẫu',
    TRIGGER_OPTION_TUTORIAL: 'Xem hướng dẫn',
    TRIGGER_OPTION_UPLOAD: 'Tải lên file từ máy',
    MSG_UPLOAD_LOADING: 'Đang xử lý...',
    MSG_ERROR_NO_CONTENT:
      'Hệ thống không đọc được dữ liệu từ đề mà bạn tải lên. Vui lòng kiểm tra lại đề theo mẫu cho trước.',
    MSG_ERROR_WRONG_CONTENT:
      'Hệ thống lọc ra được {0} câu hỏi, trong đó có {1} câu chưa đúng yêu cầu. Vui lòng kiểm tra lại các câu sau: {2}',
    MSG_ERROR_BUSY: 'Hệ thống đang có nhiều yêu cầu xử lý đề trong thời điểm hiện tại. Vui lòng thử lại trong ít phút.',
  },
}
const COURSE_UNIT_RESOURCE_SELECTION = {
  LABEL_PDF_SLIDE: 'PDF slide',
  LABEL_TEST: 'Bài kiểm tra (đề tự tạo)',
  LABEL_EXERCISE: 'Bài kiểm tra (đề có sẵn)',
  LABEL_MATRIX: 'Bài kiểm tra (ma trận đề)',
  LABEL_HOMEWORK: 'Bài tập về nhà',
  LABEL_EMBEDDED: 'Bài giảng nhúng',
  LABEL_VOICE: 'Audio',
  LABEL_READING: 'Bài kiểm tra dạng đọc',
  LABEL_LISTENING: 'Bài kiểm tra dạng nghe',
  LABEL_FLASHCARD:"Dạng bài flashcard",

  //QUESTION_SETTING
  QUESTION_SETTING: {
    TITLE: 'Câu hỏi và quy tắc làm bài',
    CHECKBOX_SHOW_RANDOM: 'Các câu hỏi xuất hiện với thứ tự ngẫu nhiên',
    CHECKBOX_SORT_AUTO: 'Nếu câu hỏi nằm trong nhóm thì nhóm đó sẽ được thay đổi thứ tự thay vì câu hỏi.',
    CHECKBOX_SORT_GROUP_AUTO:
      'Nếu bài kiểm tra chia thành nhiều phần thì các câu hỏi / nhóm câu hỏi sẽ chỉ thay đổi thứ tự trong phần.',
    CHECKBOX_MIXING_ANWSER: 'Trộn đáp án trong câu',
    CHECKBOX_RATIO_MARK: 'Các câu hỏi có tỉ lệ điểm khác nhau',
    CHECKBOX_ON_CHANGE_ONE_BY_ONE: 'Học sinh làm bài theo thứ tự từng câu hoặc nhóm câu',
    CHECKBOX_IS_DIABLE_PREVIOUS: 'Học sinh không được xem và làm lại những câu hỏi đã hoàn thành',
    CHECKBOX_HAS_MIN_SCORE: 'Yêu cầu học sinh đạt điểm tối thiểu',
    MIN_SCORE: 'Điểm tối thiểu',
  },

  POPUP_CONFIRM_DELETE_LESSON_TITLE: 'Xoá bài học',
  POPUP_CONFIRM_DELETE_LESSON_MESSAGE:
    'Dữ liệu sau khi xóa không thể khôi phục. Hệ thống sẽ tự động xóa toàn bộ nội dung thuộc về bài giảng này. Bạn có chắc chắn muốn thực hiện thao tác này ?',
  MSG_DELETE_LESSON_SUCCESS: 'Xoá bài giảng thành công!',
  //COURSE_EDIT_UNIT_WITH_TYPE_VIDEO....
  SUBTITLE_LIMIT_CAPACITY_FOR_VIDEO: 'Giới hạn dung lượng tải lên là 2.5GB',
  TITLE_VIDEO: 'Video',

  //COURSE_EDIT_UNIT_WITH_TYPE_VIDEO_EXTERNAL
  SUBTITLE_VIDEO_LINK_HINT: "Hãy sử dụng đường dẫn nhúng video (thường có dạng 'https://...embed...')",
  LABEL_VIDEO_LINK: 'Đường dẫn video',
  PLACEHOLDER_VIDEO_LINK: 'Nhập đường dẫn video',
  PLACEHOLDER_INPUT_LINK: 'Nhập đường dẫn',

  //COURSE_EDIT_UNIT_WITH_TYPE_PDF
  TEST_PDF: {
    TITLE_ANSWER: 'Đáp án',
    QUESTION_FORM: {
      BUTTON_ADD_ONCE_QUESTION: 'Thêm 1 câu',
      BUTTON_ADD_MANY_QUESTION: 'Thêm nhiều câu',
    },
  },
  //COURSE_EDIT_UNIT_WITH_TYPE_TEST
  LABEL_TOTAL_SCORE: 'Tổng điểm',
  LABEL_TOTAL_TITME: 'Thời gian',
  BUTTON_ADD_PATH: 'Thêm phần',
  LABEL_UNTITLED: 'Chưa có tiêu đề',
  LABEL_TIME_FOR_PART: 'Thời gian cho phần',
  BUTTON_EDIT_PART: 'Sửa phần',
  BUTTON_DELETE_PART: 'Xoá phần',
  //CREATE_QUESTION_TEST
  BUTTON_ADD_ONCE_QUESTION: 'Thêm 1 câu',
  BUTTON_ADD_QUESTIONS: 'Thêm nhiều câu',
  //CREATE_QUESITON_DIALOG
  TITLE_ADD_MANY_QUESTION: 'Thêm nhiều câu hỏi',
  SWITCH_QUESTION_MANY_ANSWER: 'Câu hỏi nhiều đáp án',
  LABEL_QUESTION_TYPE: 'Loại câu',
  BUTTON_FILL_IN_BLANK: 'Điền vào chỗ trống',
  LABEL_TIME_FOR_QUESTION: 'Thời gian cho từng câu hỏi (tính theo giây)',
  PLACEHOLDER_TIME_FOR_QUESTION: 'Nhập thời gian',
  LABEL_MARK_FOR_QUESTION: 'Điểm cho từng câu hỏi (thang điểm 10)',
  PLACEHOLDER_IMPORT_MARK: 'Nhập điểm',
  //EDIT_PART_DIALOG
  TITLE_EDIT_PART_DIALOG: 'Sửa phần',
  TITLE_ADD_NEW_PART_DIALOG: 'Thêm phần mới',
  LABEL_PART_TITLE: 'Tiêu đề',
  PLACEHOLDER_IMPORT_PART: 'Nhập tiêu đề cho phần câu hỏi',
  TITLE_SETTING_TIME_FOR_PART: 'Cài đặt thời gian cho phần',
  RADIO_IMPORT_TIME: 'Tự nhập thời gian',
  RADIO_SYTEM_CACULATE_TIME: 'Cho phép hệ thống tự tính toán dựa trên các câu hỏi',
  LABEL_DURATION_AUTO_CACULATE: 'Thời gian làm bài (tính theo giây)',
  PLACEHOLDER_IMPORT_TEST_TIME: 'Nhập thời gian làm bài',
  POPUP_CONFIRM_DELETE_PART_QUESTION_TITLE: 'Xoá phần câu hỏi',
  POPUP_CONFIRM_DELETE_PART_QUESTION_MESSAGE:
    'Xóa phần câu hỏi sẽ xóa toàn bộ câu hỏi trong phần, bạn có chắc chắn muốn xóa phần này ?',
  MSG_CHECK_MART_TEST_AGAIN: 'Tổng số điểm của bài kiểm tra phải bằng 10 !',
  MSG_CHECK_NUMBER_QUESTION: 'Số lượng câu hỏi phải lớn hơn 0',
  //CREATE_QUESTION_TEST
  //LIST_QUESTION_TEST
  TITLE_QUESTION: 'Câu hỏi',
  TITLE_QUESTION_CHILD: 'Câu hỏi con',
  TITLE_QUESTION_CHILD_GROUP: 'câu hỏi con - nhóm',
  LABEL_QUESTION_GROUP: 'Nhóm câu hỏi',
  LABEL_IMPORT_QUESTION_CONTENT: 'Nhập câu trả lời...',
  LABEL_IMPORT_DESCRIPTION_QUESTION_CONTENT: 'Nhập mô tả',
  BUTTON_SAVE_QUESTION_CONTENT: 'OK',

  POPUP_DELETE_QUESTION_TITLE: 'Bạn có chắc chắn xoá câu hỏi này không?',
  POPUP_DELETE_QUESTION_MESSAGE:
    'Dữ liệu sau khi xóa không thể khôi phục. Hệ thống sẽ tự động xóa toàn bộ nội dung và đáp án thuộc về khóa học này. Bạn có chắc chắn muốn xóa câu hỏi này ?',
  MSG_CHECK_ANSWER_FOR_QUESTION: 'Còn thiếu câu trả lời cho câu ',

  //COURSE_EDIT_UNIT_WITH_TYPE_EXERCISE
  SUBTITLE_IMPORT_REQUIREMENT_OF_TEACHER: 'Nhập yêu cầu của giáo viên',
  LABEL_ATTACH_FILE: 'Tệp đính kèm',
  PLACEHOLDER_PICK_UPLOAD_FILE: 'Chọn file tải lên',
  LALBE_SECOND_UNIT: 'giây',

  //COURSE_EDIT_UNIT_WITH_TYPE_HOMEWORK
  TITLE_ASSIGN_TEACHER: 'Phân công giảng viên chấm bài',
  SUBTITLE_ASSIGN_TEACHER:
    'Giảng viên được phân công chỉ có thể xem bài nộp từ học viên và thực hiện thao tác chấm điểm. Các thông tin về học viên như họ tên, email sẽ được ẩn đi để đảm bảo tính khách quan trong quá trình chấm điểm.',
  LABEL_CHANGES_NOT_SAVE_LESSON: 'Thay đổi của bạn chưa được lưu. Vui lòng lưu lại bài giảng để hoàn tất thay đổi.',
  LABEL_TEACHER_GRADING_LESSONS: 'Giảng viên chấm bài',
  PLACEHOLDER_TEACHER_GRADING_LESSONS: 'Chọn giảng viên chấm bài',
  TOOLTIP_TEACHER_MARK_LAST: 'Giảng viên chấm bài lần cuối',
  TOOLTIP_MARK_LAST_TIME: 'Thời gian chấm bài lần cuối',
  TOOLTIP_COPY_SUBJECTS: 'Sao chép bài nộp',
  BUTTON_ASSIGN: 'Phân công',
  MSG_DISTRIBUTION_TEACHER: 'Phân công giảng viên chấm bài thành công!',
  MSG_PLEASE_TRY_DISTRIBUTION_TEACHER_AGAIN: 'Hiện chưa thể phân công giảng viên được. Vui lòng thử lại sau.',
  MSG_CHECK_PICK_TEACHER_MARK: 'Vui lòng chọn giảng viên chấm bài',
  MSG_REMOVE_ASSIGN_TEACHER_SUCCESS: 'Bỏ phân công giảng viên chấm bài thành công!',
  MSG_CHECK_REMOVE_ASSIGN_TEACHER_AGAIN: 'Hiện chưa thể bỏ phân công giảng viên được. Vui lòng thử lại sau.',
  MSG_PICK_TEACHER_WANT_REMOVE: 'Vui lòng chọn giảng viên muốn bỏ phân công',

  //COURSE_EDIT_UNIT_WITH_TYPE_MATRIX
  //DOWNLOAD_EXAM_POPUP
  TITLE_CONFIG_DOWNLOAD: 'Cấu hình đề tải về',
  LABEL_THREADS_TITLE: 'Tiêu đề của đề',
  PLACEHOLDER_IMPORT_EXAM_TITLE: 'Nhập tiêu đề cho đề kiểm tra',
  TITLE_TEST_TIME: 'Thời gian làm đề (phút)',
  DOWNLOAD_SUBJECTS: 'Tải đề về máy',
  ENTITY_PAGE: 'trang',
  TITLE_EXAM_CODE: 'Mã đề',
  MSG_DATA_LOADING: 'Đang tải dữ liệu',
  PLACEHOLDER_IMPORT_ANWSER: 'Nhập câu trả lời',
  LABEL_MARK: 'Điểm',
  LABEL_TIME: 'Thời gian',
  LABEL_ANWSER_UNIT: 'đáp án',
  MSG_CHECK_CREATE_DATA: 'Chưa tạo dữ liệu đề kiểm tra',
  MSG_CHECK_EXAM_CODE_AGAIN: 'Vui lòng kiểm tra lại mã đề',
  MSG_DOWNLOAD_EXAM_SUCCESS: 'Tải đề thành công!',
  MSG_CREATING_EXAM: 'Đang khởi tạo...',
  //TABLE_MATRIX
  TH_QUESTION_LEVEL: 'Mức độ (câu/điểm)',
  TH_TOTLA_MARK: 'Tổng',
  BUTTON_ADD_TOPIC: 'Thêm chủ đề',
  LABEL_EXAM_CODE_QUANTITY: 'Số lượng mã đề',
  PLACEHOLDER_EXAM_CODE_QUANTITY: 'Nhập số lượng đề cần tạo',
  TH_TOPIC_QUESTION: 'Chủ đề câu hỏi',
  TH_PLUSS_POINT: 'Cộng',
  //ADD_TOPIC_MATRIX_DIALOG:""
  TITLE_MAXTRIX_CONTENT: 'Thêm nội dung ma trận đề',
  LABEL_TOPIC: 'Chủ đề',
  PLACEHOLDER_TOPIC: 'Chọn chủ đề',
  LABEL_LEVEL_QUESTION: 'Mức độ:',
  PLACEHOLDER_IMPORT_MARK_NUMBER: 'Nhập số điểm',
  LABEL_QUESTION_UNIT: 'câu',
  LABEL_POINT_UNIT: 'điểm',
  BUTTON_CREATE_TEST_MATRIX: 'Tạo',

  MSG_IMPORT_INFO_ONCE_LEVEL: 'Nhập đầy đủ thông tin ít nhất 1 cấp độ  !',

  MSG_CHECK_QUESTION_QUANTITY_SETTING_AGAIN: 'Số câu cài đặt lớn hơn số câu trên hệ thống, vui lòng kiểm tra lại!',
  MSG_CHECK_TOTAL_MARK_AGAIN: 'Tổng điểm phải bằng 10 !',
  MSG_CHECK_MARK_AGAIN: 'Kiểm tra lại điểm, thời gian hoặc đáp án của {0}',
  MSG_CHECK_TOPIC_AGAIN: 'Vui lòng kiểm tra lại chủ đề !',
  MATRIX_FUNC: {
    POPUP_CONFIRM_CREATE_NEW_TITLE: 'Tạo mã đề mới',
    POPUP_CONFIRM_CREATE_NEW_MESSAGE:
      'Hệ thống sẽ xóa các mã đề cũ và tạo lại các mã đề mới theo ma trận đề bạn mới cập nhật. Bạn có chắc chắn muốn tiếp tục ?',
    MESSAGE_CREATE_NEW: 'Số đề tối đa có thể tạo là 1000',

    POPUP_CANCEL_RESULT_OF_STUDENT_TITLE: 'Hủy kết quả cũ của học viên',
    POPUP_CANCEL_RESULT_OF_STUDENT_MESSAGE:
      'Đã có học viên làm đề cũ và có kết quả. Bạn có muốn hệ thống xóa kết quả của các học viên đó hay không ?',
    MSG_REMOVE_RESULT_OF_STUDENT_SUCCESS: 'Xoá kết quả thành công !',
    ENTITY_TEST: 'Bài thi',
    ENTITY_EXAM: 'đề thi',
    ENTITY_QUESTION: 'câu hỏi',
    MSG_CREATE_EXAM_SUCCESS: 'Tạo đề thi thành công',
  },
  EMBEDDED_LESSON: {
    TITLE: 'Đường dẫn nhúng',
    PLACEHOLDER: 'Nhập đường dẫn nhúng tại đây',
  },
  TEST_MATRIX: {
    TITLE: 'Nội dung chi tiết',
    NO_PARTITION_MATRIX: {
      TOPIC_MATRIX: {
        TITLE_MATRIX_CONTENT: 'nội dung ma trận',
        LABEL_TOPIC: 'Chủ đề',
        LABEL_DIFFICULTY_LEVELS: 'Mức độ khó',
        LABEL_LEVELS: 'Mức độ',
        LABEL_NUMBER: 'Số câu',
        TOTAL_TIME: {
          LABEL_TOTAL_TIME: 'Tổng thời gian',
          LABEL_EVERY_QUESTION: 'Mỗi câu',
        },
        TOTAL_SCORE: {
          LABEL_TOTAL: 'Tổng điểm',
          LABEL_EVERY_QUESTION: 'Mỗi câu',
        },
        RADIO_SELECT_QUESTION_RANDOM: 'Chọn ngẫu nhiên câu hỏi và nhóm câu hỏi',
        RADIO_SELECT_QUESTION_SIGNLE_RANDOM: 'Chỉ chọn ngẫu nhiên các câu hỏi riêng lẻ',
        RADIO_SELECT_QUESTION_GROUP_RANDOM: 'Chỉ chọn ngẫu nhiên các nhóm câu hỏi',
        //MESSAGE
        MSG_INPUT_FULL_INFO_ONCE_LEVEL: 'Nhập đầy đủ thông tin nhất 1 cấp độ  !',
      },
      MSG_ALREADY_EXIST: 'Chủ đề đã tồn tại !',
    },
    PARTITION_MATRIX: {
      PART_HEADER: {
        LABEL: 'Tiêu đề',
      },
    },
    LABEL_EXAM_CODE_QUANTITY: 'Số lượng mã đề',
    PLACEHOLDER_INPUT_EXAM_CODE_QUANTITY: 'Nhập số lượng đề cần tạo',
  },
  QUESTION_SELECT_TYPE: {
    LABEL_ADD_QUESTION_QUANTITY: 'Số lượng câu cần thêm',
    TOGGLE_QUESTION_MANY_ANWSER: 'Câu hỏi có nhiều đáp án',
    LABEL_CONNECTIVE: 'Liên kết',
    CHECKBOX_CREATE_QUESTION_IN_ONCE_GROUP: 'Các câu hỏi được tạo nằm trong 1 nhóm',
    BUTTON_ANWSER: 'đáp án',
    BUTTON_FILL_BLANK: 'Điền vào chỗ trống',
    BUTTON_EVI_SPACE: 'Điền trực tiếp vào câu hỏi',
  },
  QUESTION_CHOICE_TYPE: {
    BUTTON_CHOICE: 'Câu hỏi lựa chọn đúng sai',
  },
  //edit_unit_function
  ENTITY_TEST: 'đề thi',
  ENTITY_QUESTION: 'câu hỏi',
  MSG_CRATE_TEST_SUCCESS: "'Tạo đề thi thành công'",
  MSG_CONFIRM_DELETE_OLD_TEST_TITLE: 'Hệ thống sẽ xoá đề cũ và tạo lại đề mới, bạn có chắc chắn muốn thực hiện ?',
  MSG_CONFIRM_DELETE_OLD_TEST_MESSAGE: 'Dữ liệu sau khi xóa không thể khôi phục',
  MSG_MAXIMUM_NUMBER_OF_TOPICS_CREATED: 'Số đề tối đa có thể tạo là 1000',

  //CoursePopupSelectDuplicateUnit
  TITLE_COPY_OLD_LESSON: 'Sao chép từ bài giảng cũ',
  LABEL_SELECT_LESSON: 'Chọn bài giảng',
  PLACEHOLDER_FILTER_LESSON: 'Tìm kiếm bài giảng',
  MSG_CREATING_LESSON: 'Đang tạo bài giảng...',
  MSG_CREATE_LESSON_SUCCES: 'Tạo bài giảng thành công !',
  MSG_NOT_FULL_CONFIGURATION: 'Bài giảng này chưa được cấu hình đầy đủ !',
  MSG_COPY_LESSON_SUCCESS: 'Sao chép bài giảng thành công!',
  LIST_ONLINE_COURSE: 'Danh sách khoá học Online',

  //CHECK_LIST_SETTING
  TITLE_STRUCTURE_OF_TEST_DIVISION: 'Cấu trúc bài kiểm tra được chia thành nhiều phần',
  TITLE_STRUCTURE_OF_THE_TEST_WITHOUT_DISTRIBUTION: 'Cấu trúc bài kiểm tra không chia phần',
  CHECKBOX_QUESTIONS_ALREADY_ANSWERING: 'Các câu hỏi xuất hiện với thứ tự ngẫu nhiên',
  CHECKBOX_DIFFERENT_POINT_RATE: 'Các câu hỏi có tỉ lệ điểm khác nhau',
  CHECKBOX_MIX_ANSWERS: 'Trộn đáp án trong câu',
  CHECKBOX_QUESTION_ONE_BY_ONE: 'Học sinh làm bài theo thứ tự từng câu một hoặc từng nhóm câu một',
  CHECKBOX_DISABLE_PREVIOUS: 'Học sinh không được xem và làm lại những câu hỏi đã hoàn thành',

  CHECKBOX_TIME_LIMIT_FOR_EXAM: 'Thời gian làm bài giới hạn',
  CHECKBOX_TIME_LIMIT_DISTRIBUTE_PARTS: 'Thời gian làm bài giới hạn và chia theo từng phần',
  CHECKBOX_TIME_LIMIT_DISTRIBUTE_SENTENCE: 'Thời gian làm bài giới hạn và chia theo từng câu',
  CHECKBOX_UNLIMITED_TIME_FOR_EXAM: 'Thời gian làm bài không giới hạn',

  CHECKBOX_SCOPE_OF_TEN_POINTS: 'Điểm số tính theo thang điểm 10',
  CHECKBOX_POINT_FOR_SUMMARY_OF_QUESTIONS: 'Điểm số tính theo tổng điểm các câu hỏi',
}
const COURSE_SECTION_REPORT = {
  RESCORING: {
    ENTITY_TEST: 'Bài kiểm tra',
    POPUP_CONFIRM_UPDATE_RESULT_OF_STUDENT_TITLE: 'Cập nhật kết quả của học viên',
    POPUP_CONFIRM_UPDATE_RESULT_OF_STUDENT_MESSAGE:
      'Bạn vừa thay đổi nội dung trong bài kiểm tra. Đã có học viên làm bài và có kết quả. Hệ thống sẽ tự động cập nhật lại điểm cho các học viên đó.',
    MESSAGE_UPDATE_SUCCESS: 'Cập nhật thành công!',
  },
  COURSE_REPORT_WRAPPER: {
    LABEL_GROUP_NAME: 'Tên nhóm',
    PLACEHOLDER_INPUT_GROUP: 'Nhập tên nhóm và chọn',
    BUTTON_VIEW_FILTER: 'Lọc và xem',
    TEXT_HOMEWORK: 'bài tập về nhà',
    TEXT_EXAM: 'Bài kiểm tra',
  },
  LINK_RETURN_TO_EXAM_LIST: 'Danh sách bài kiểm tra',
  BUTTON_VIEW_REQUIMENT: 'Xem đề bài',
  //----------COURSE_REPORT_HOMEWORK-------------
  HOMEWORK_FILTER: {
    LABEL_FILTER_BY_GROUP: 'Lọc theo nhóm',
    PLACEHOLDER_INPUT_AND_GROUP_NAME: 'Nhập tên nhóm và chọn',
    LABEL_STUDENT_NAME: 'Tên học viên',
    PLACEHOLDER_FILTER_BY_STUDENT_NAME: 'Tìm kiếm theo tên học viên',
    LABEL_STATUS_SUBMIT: 'Trạng thái nộp bài',
    PLACEHOLDER_STATUS_SUBMIT: 'Trạng thái',
    OPTION_ALL: 'Tất cả',
    OPTION_SUBMITTED: 'Đã nộp bài',
    OPTION_APPROVED: 'Đã duyệt',
    OPTION_HAVED_REFUSED: 'Đã từ chối',
    OPTION_NOT_SUBMITTED: 'Chưa nộp',
  },
  HOMEWORK_ACTION: {
    MSG_ACCEPT_SUCCESS: 'Duyệt bài thành công',
    MSG_REFUSED_SUCCESS: 'Từ chối thành công',
    MSG_SAVE_SUCCESS: 'Lưu bài chữa thành công',
  },
  LABEL_FILTER_COURSE: 'Chọn khoá học',
  LABEL_ALL: 'Tất cả',
  LABEL_FILTER_TEST_BY_REQUEST: 'Chọn bài kiểm tra theo yêu cầu',
  ASSIGNED_HOMEWORK: {
    TITLE: 'Danh sách bài kiểm tra',
    TH_COURSE: 'Khoá học',
    TH_UNIT_TITLE: 'Bài kiểm tra',
    TH_HOMEWORK_COUNT: 'Số bài nộp',
    TH_IS_ALL_HOMEWORK_MARKER: 'Đã chấm hết',
    TH_ACTION: 'Thao tác',
  },
  POPUP_REQUIREMENT: {
    TITLE: 'Đề bài',
    TITLE_INTRODUCT: 'Giới thiệu',
    TITLE_REQUIREMENT_OF_EXERCISE: 'Yêu cầu của bài tập',
  },

  //HOMEWORK_EXCEL_EXPORT
  BUTTON_EXPORT_EXCEL: 'Xuất Excel',
  TH_STUDENT: 'Học viên',
  TH_EMAIL: 'Email',
  TH_STATUS: 'Trạng thái',
  TEXT_WATCHED: 'Đã xem',
  TEXT_NOT_SEEN_LEARNING: 'Chưa xem bài giảng',
  TH_TIME: 'Thời gian',
  TH_POINT: 'Điểm',
  TEXT_NOT_POINT: 'Chưa có điểm',
  TH_EXERCISE_NAME: 'Tên bài tập',

  //HOMEWORK_ARCHIVEALL
  BUTTON_DOWNLOAD_ALL_EXCEL: 'Tải tất cả',
  ENTITY_EXERCISE_STUDENT: 'Bài tập của học viên',
  MSG_GROUP_STUDENT_DOES_NOT_EXIST: 'Nhóm không tồn tại',
  MSG_COMPRESSING: 'Đang nén...',
  MSG_COMPRESSING_TO: 'Đang nén bài tập của học sinh thứ',
  //TABLE_HOMWORK
  BUTTON_DOWLOAD_FILE_SELECTED: 'Tải file đã chọn',
  TOOLTIP_TEACHER_ACCEPT_LAST_LESSON: 'Giảng viên chấm bài lần cuối',
  TOOLTIP_TIME_ACCEPT_LAST_LESSON: 'Thời gian chấm bài lần cuối',
  TH_HOMEWORK_NAME: 'Tên bài',
  TH_STUDENT_NAME: 'Tên học viên',
  TH_SUBMIT_TIME: 'Thời gian nộp bài',
  //TH_STATUS:"Trạng thái",
  TH_MARK: 'Điểm',
  TH_TEACHER: 'Giảng viên',
  TH_MARK_AT: 'Thời gian chấm điểm',
  TH_ACTION: 'Thao tác',
  TEXT_NO_SCORE: 'Chưa chấm',
  TEXT_SUBMITTED: 'Đã nộp bài',
  TEXT_APPROVED: 'Đã duyệt',
  TEXT_REFUSE: 'Từ chối',
  TEXT_UNSUBMIT: 'Chưa nộp',

  //POPUP_VIEW_HOMEWORK
  TITLE_VIEW_STUDENTS_LESSONS: 'Xem bài của học viên',
  LABEL_EXERCISE_TYPE_LINK: ' Bài tập được nộp dưới dạng đường link',
  LABEL_CLICK_LINK: 'Nhấn vào đường link bên dưới để xem chi tiết bài tập của học viên',
  BUTTON_MARKING: 'Chấm bài',
  MSG_DOWNLOADING: 'Đang tải...',
  MSG_NOT_FOUND_LINK_PDF: 'Không tìm thấy đường dẫn file pdf! Vui lòng thử lại.',

  //POPUP_REPORT_APPROVED_HOMEWORK
  TITLE_BROWSER_EXERCISE: 'Duyệt bài tập',
  SUBTITLE_BROWSER_EXERCISE:
    'Duyệt bài tập này đồng nghĩa với việc đóng lại bài giảng vừa rồi và học viên không thể gửi bài tập cho bài giảng này lần nữa.',
  SUBTITLE_DISPLAY_RESULT:
    'Kết quả sẽ được hiển thị trên hệ thống của học sinh. Bên cạnh đó, bạn có thể gửi nhận xét cho học sinh bằng việc chọn "Gửi kèm nhậnxét" ở dưới',
  LABEL_MARKING: 'Chấm điểm',
  LABEL_MARKING_BY_NUMBER: 'Chấm điểm theo số',
  LABEL_COMMENT: 'Nhận xét',
  PLACEHOLDER_IMPORT_COMMENT_FOR_HOMEWORK: 'Nhập nhận xét cho bài tập về nhà của học sinh',
  LABEL_ATTACH_COMMENT: 'Gửi kèm nhận xét',
  MSG_ACCEPT_EXERCISE_SUCCESS: 'Duyệt bài tập thành công',
  MSG_ACCEPT_EXERCISE_LOADING: 'Đang duyệt bài tập',
  SUBJECT_COMMENT_HOMEWORK: 'Nhận xét bài tập về nhà',
  BUTTON_ACCEPT: 'Duyệt',
  MSG_CHECK_LINK_FILE_PDF: 'Không tìm thấy đường dẫn file pdf! Vui lòng thử lại.',

  //POPUP_DENY_HOMEWORK
  TITLE_DENY_HOMEWORK: 'Từ chối bài tập',
  SUBTITLE_DENY_HOMEWORK:
    'Từ chối bài tập này đồng nghĩa với việc mở lại bài giảng và học viên có quyền gửi bài tập lại lần nữa.',
  SUBTITLE_DENY_HOMEWORK_NOTE:
    'Ngoài ra, bạn có thể gửi lý do yêu cầu nộp lại bài bằng cách bấm chọn “Gửi kèm nhận xét” ở dưới.',
  //LABEL_COMMENT:"Nhận xét",
  PLACEHOLDER_COMMENT: 'Nhập nhận xét cho bài tập về nhà của học sinh',

  MSG_TIME_SUBMIT_NOT_YET: 'Chưa hết thời gian nộp bài !',
  MSG_ACCEPT_EXERCISE: 'Duyệt bài thành công',
  MSG_REFUSE_SUCCESS: 'Từ chối thành công',
  MSG_SELECT_GROUP: 'Vui lòng chọn nhóm',

  //----------COURSE_REPORT_DASHBOARD_EXAM	-------------
  TITLE_REPORT_DASHBOARD_EXAM: 'Kết quả',
  LABEL_STUDENT_DONE: 'Số học viên đã hoàn thành',
  TEXT_STUDENT: 'học viên',
  LABEL_STUDENT_TOP: 'Học viên xếp hạng cao nhất',
  TEXT_NOT_NAME: 'Không có tên',
  TEXT_NOT_EMAIL: 'Không có email',
  TEXT_DO_NOT_EXAM: 'Chưa làm bài',
  LABEL_NO_ONE_SUBMIT: 'Chưa có học viên nào hoàn thành bài kiểm tra này',
  LABEL_MARK_DISTRIBUTION: 'Phân bố điểm',
  LABEL_AVERAGE_SCORE: 'Điểm trung bình',
  LABEL_MOST_INCORRECT_QUESTIONS: 'Câu hỏi sai nhiều nhất',
  LABEL_NOT_DATA_YET: 'Chưa có dữ liệu !',
  LABEL_INCORRECT_ANSWER: 'Trả lời sai',
  LABEL_TOTAL_QUESTION: 'Trên tổng số',
  LABEL_REPORT_DETAIL: 'Báo cáo chi tiết',
  //POPUP_QUESTION_REPORT
  TITLE_QUESTION_REPORT: 'Báo cáo chi tiết',
  PLACEHOLDER_PICK_EMAIL_TYPE: 'Chọn kiểu mẫu email',
  BUTTON_SHOW_PDF: 'Xem đề',
  LABEL_PAGE: 'Trang',
  LABEL_NOT_DATA: 'Chưa có dữ liệu, vui lòng quay lại sau !',
  TEXT_QUESTION: 'Câu',
  TEXT_NOT_MARK: 'Chưa có điểm',
  //SHOW_ANALYTIC
  TITLE_QUESTION_CONTENT: 'Nội dung câu hỏi',
  TITLE_STATISTIC_QUESTION: 'Thống kê câu trả lời',
  TEXT_QUESTION_COUNT_WRONG: 'Sai nhiều nhất',
  TEXT_COUNT_WRONG: 'Sai ít nhất',

  //-----------REPORT_DETAIL_EXAM------------
  OPTION_LAEDER_BOARD: 'Xem báo cáo theo kết quả xếp hạng',
  OPTION_REPORT_TABLE_EXAM_VIEW: 'Xem báo cáo theo kết quả bài kiểm tra',
  TEXT_ALL: 'Tất cả',
  BUTTON_SUBMITED: 'Trạng thái: Đã hoàn thành',
  BUTTON_DOING: 'Trạng thái: Đang làm',

  TOOLTIP_FILTER_REPORT_EXAM_BY_RESULTS:
    'Khi xem báo cáo theo kết quả bài\r\nkiểm tra, giảng viên có thể nhìn thấy\r\ntrạng thái làm bài và chọn xem theo\r\nnhóm học viên tham gia vào bài kiểm\r\ntra này',
  TOOLTIP_FILTER_REPORT_EXAM_BY_RATING:
    'Khi xem báo cáo theo bảng xếp hạng,\r\ngiảng viên có thể biết được thứ tự xếp\r\nhạng kết quả làm bài của học viên',
  PLACEHOLDER_IMPORT_PICK_NAME: 'Nhập tên và chọn',
  LABEL_FILTER_BY_STUDENT_NAME: 'Tên học viên',
  PLACEHOLDER_FILTER_BY_STUDENT_NAME: 'Tìm kiếm theo tên học viên',
  //XEM LẠI TEXT TABLE HEADER
  //TH_STATUS:"Trạng thái",
  TH_SUBMIT_COUNT: 'Lần làm',
  TH_AVAILABEL_TIME: 'Bắt đầu',
  MSG_CHECK_GROUP_LEARNING: 'Vui lòng chọn nhóm học',

  //TABLE REPORT LEADER BOARD
  //LABEL_FILTER_BY_STUDENT_NAME:"Tìm kiếm theo tên học sinh",
  BUTTON_RELOAD: 'Tải lại',
  TH_RANK: 'Xếp hạng',
  //TH_STUDENT:"Học viên",
  TH_LESSON_NAME: 'Tên bài',
  TH_START_TIME_SUBMIT: 'Nộp bài',
  TH_LAST_TIME_SUBMIT: 'Thời gian nộp bài lần cuối',
  //TH_TIME:"Thời gian",
  TH_OUT_FOCUS: 'Không tập trung',
  TH_RESULT: 'Kết quả',
  //TH_POINT:"Điểm",
  TH_DETAIL: 'Chi tiết',
  MSG_DELETE_EXAM_SUCCESS: 'Xoá bài kiểm tra thành công !',

  LABEL_RESULT_EXERCISE_DETAIL: 'Chi tiết bài tập - Kết quả',
  BUTTON_CANCEL_EXAM: 'Huỷ bài kiểm tra',
  TEXT_EMPTY: 'Bỏ trống',
  //-----------COURSE_REPORT_PDF_AND_VIDEO----------------
  //TH_STUDENT_NAME:"Tên học viên",
  TH_LAST_VIEW: 'Xem gần nhất lúc',
  //TH_STATUS:"Trạng thái",

  //EXPORT_EXCEL_PDF_AND_VIDEO
  //TH_STUDENT:"Học sinh",
  //TH_EMAIL:"Học viên",
  //TH_TIME:"Thời gian",
  //TH_STATUS:"Trạng thái",
  TEXT_VIEWED: 'Đã xem',
  TEXT_VIEWING: 'Đang xem',
  TEXT_NOT_VIEW: 'Chưa xem',
  TEXT_NOT_VIEW_LESSON: 'Chưa xem bài giảng',
  TEXT_TOTAL: 'tổng',

  MSG_CHECK_GROUP_STUDENT_AGAIN: 'Vui lòng chọn nhóm học',

  POPUP_CONFIRM_DELETE_EXAM_TITLE: 'Bạn có chắc muốn xóa bài kiểm tra của {0}',
  POPUP_CONFIRM_DELETE_EXAM_MESSAGE: 'Dữ liệu sau khi xóa không thể khôi phục',

  MSG_GETTING_GROUP_DATA: 'Đang lấy dữ liệu nhóm học...',

  //-----------COURSE_REPORT_DEFAULT------------
  LABEL_FILTER_GROUP: 'Lọc theo nhóm',
  PLACEHOLDER_FILTER_GROUP: 'Nhập tên hoặc email',
  CHECKBOX_ALL: 'Tất cả',
  BUTTON_VIEW_FILTER: 'Lọc và xem',
  LABEL_CHECK_PICK_LESSON: 'Vui lòng chọn một Bài giảng & Nhóm để lọc kết quả',
  LABEL_REPORT_TABLE_SUBTITLE:
    'Sau khi chọn Kết quả ở thanh bên trái & Lọc nhóm phía trên, kết quả học viên sẽ được hiển thị như hình mẫu bên dưới.',

  TH_GROUP: 'Nhóm',
  //TH_STUDENT_NAME:"Tên học sinh",
  //TH_EMAIL:"Email",
  TH_LAST_STUDY: 'Lần cuối học',
  //TH_LAST_STUDY:"Bài học gần nhất",
  TH_PROGRESS: 'Tiến độ học',
  //TH_TIME:"Thời gian",
  TH_POIN: 'Điểm',
  TEXT_DID: 'Đã làm',

  TEXT_EXAMPLE_CLASS: 'Lớp 7A1',
  TEXT_EXAMPLE_NAME: 'Nguyễn Văn A',
  TEXT_EXAMPLE_EMAIL: 'nguyenvana@gmail.com',
  TEXT_EXAMPLE_TIME: '13:30, 12/12/2020',
  TEXT_EXAMPLE_LESSON: 'Bài 1: Chuyển động',
  TEXT_IS_DONE: 'Đã hoàn thành',
  //HOMEWORK
  LABEL_SUBJECTS_CODE: 'Mã bài nộp',
  PLACEHOLDER_INPUT_SUBJECTS_CODE: 'Nhập mã bài nộp',
  WATCHED: 'Đã xem',
  WATCHING: 'Đang xem',
  NOT_WATCH: 'Chưa xem',
  TH_SUBMIT_CODE: 'Mã nộp bài',
  TH_TIME_SUBMIT: 'Thời gian nộp bài',
  TH_POINTING_TIME: 'Thời gian chấm điểm',

  TITLE_VIEW_SUBJECTS_OF_STUDENT: 'Xem bài nộp của học sinh',
}
const COURSE_REPORT_EXPORT_EXAM = {
  BUTTON_EXPORT: 'Xuất Excel',
  TH_STUDENT: 'Học viên',
  TH_EMAIL: 'Email',
  TH_START_TIME: 'Bắt đầu lúc',
  TH_SUBMIT_TIME: 'Nộp bài lúc',
  TH_COURSE_NAME: 'Tên khoá học',
  TH_LESSON_NAME: 'Tên bài học',
  TH_DURATION: 'Hoàn thành trong',
  TH_VIOLATION: 'Không tập trung',
  TH_RESULT: 'Kết quả',
  TH_POINT: 'Chưa hoàn thành',
  TH_FILE_NAME: 'Tên tệp làm bài',
  TH_STATUS: 'Trạng thái',
  TH_TIME: 'Thời gian',
  TH_MARK: 'Điểm',
  TEXT_INCOGNITO_NAME: 'Không có tên',
  TEXT_INCOGNITO_EMAIL: 'Không có email',
  TEXT_TOTAL_VIOLATION_UNIT: 'lần',
  TEXT_UNFINISHED: 'Chưa hoàn thành',
}
//TIẾN ĐỘ KHOÁ HỌC ONLINE
const COURSE_PROGRESS = {
  LABEL_GROUP_STUDENT: 'Nhóm học viên',
  PLACEHOLDER_SELECT_GROUP_STUDENT: 'Chọn nhóm học viên',
  OPTION_ALL: 'Tất cả',
  PLACEHOLDER_FILTER_BY_STUDENT_NAME: 'Tên học viên',
  BUTTON_FILTER_AND_VIEW: 'Lọc và xem',
  BUTTON_EXPORT_EXCEL: 'Xuất Excel',

  TH_GROUP: 'Nhóm',
  TH_STUDENT: 'Học viên',
  TH_EMAIL: 'Email',
  TH_LAST_STUDY: 'Lần học cuối',
  TH_LAST_VIEW_LESSON: 'Bài học gần nhất',
  TH_PROGRESS: 'Tiến độ học',
  TH_ACTION: 'Xem chi tiết',
  TEXT_PROGRESS: 'Tiến độ học tập của học sinh',

  MSG_FILTERING_STUDENT: 'Đang lọc học viên ...',
  MSG_CHECK_GROUP_STUDENT_AGAIN: 'Vui lòng chọn nhóm học',

  DETAIL: {
    HEADING_TEXT: 'Tiến độ học tập',
    SUB_TEXT: 'Tương tác học lần cuối lúc',
  },
}
const COURSE_PROGRESS_DETAIL = {
  TITLE: 'Học viên',
  LABEL_STATUS: 'Trạng thái',
  PLACEHOLDER_STATUS: 'Trạng thái bài nộp',
  PLACEHOLDER_SELECT_STATUS: 'Chọn trạng thái',
  LABEL_LESSON_TYPE: 'Loại bài giảng',
  PLACEHOLDER_SELECT_LESSON_TYPE: 'Chọn loại bài giảng',
  PLACEHOLDER_FILTER_LESSON_BY_NAME: 'Tìm kiếm theo tên bài',
  OPTION_STATUST_ALL: 'Tất cả',
  OPTION_STATUST_DONE: 'Đã hoàn thành',
  OPTION_STATUST_NOT_START: 'Chưa bắt đầu',
  OPTION_NO_SCORE: 'Chưa chấm',
  OPTION_APPROVED: 'Đã duyệt',
  BUTTON_FILTER_AND_VIEW: 'Lọc và xem',

  OPTION_TYPE_ALL: 'Tất cả',
  OPTION_TYPE_VIDEO: 'Video',
  OPTION_TYPE_PDF: 'Tài liệu pdf',
  OPTION_TYPE_PDF_TEST: 'Bài kiểm tra với đề PDF có sẵn',
  OPTION_TYPE_ADVANCE_TEST: 'Bài kiểm tra nâng cao với nhiều tuỳ chỉnh',
  OPTION_TYPE_MATRIX: 'Bài kiểm tra ngẫu nhiên theo ma trận đề',
  OPTION_TYPE_HOMEWORK: 'Bài tập về nhà',

  TH_LESSON: 'Bài',
  TH_DURATION: 'Thời lượng',
  TH_IS_DONE: 'Đã hoàn thành',
  TH_STUDY_COUNT: 'Số lần học',

  TH_TOTAL_TIME: 'Tổng thời gian',
  TH_LAST_UPDATE_AT: 'Lần học cuối',
  TH_FIRST_SCORE: 'Điểm lần đầu',
  TH_LAST_SCORE: 'Điểm lần cuối',

  TITLE_SUBJECT_COMPLETE_PROGRESS: 'Tiến độ hoàn thành',
  TITLE_EXAM_DONE: 'Bài kiểm tra đã làm',
  TITLE_EXERCISE_DONE: 'Bài tập đã làm',
  TITLE_VIDEO_VIEWED: 'Bài giảng video đã xem',
  TITLE_PDF_VIEWED: 'Bài giảng pdf đã xem',
}
//THẢO LUẬN KHOÁ HỌC ONLINE
const COURSE_DICUSION = {
  TEXT_STUDENT_NAMELESS: 'Vô danh',
  TEXT_NOT_THING: 'Không có',
  TEXT_REPLIES: 'Phản hồi',

  LABEL_TIME: 'Thời gian',
  PLACEHOLDER_AVAILABLE_EXPIRED_DATE: 'Ngày bắt đầu - Ngày kết thúc',
  LABEL_STATUS: 'Tình trạng',
  OPTION_ALL_STATUS: 'Tất cả tình trạng',
  OPTION_APPROVED: 'Đã duyệt',
  OPTION_PENDING: 'Chờ duyệt',
  OPTION_WAITING: 'Đang chờ',
  BUTTON_BROWSER_ALL: 'Duyệt tất cả',
  BUTTON_FILTER_AND_FIND: 'Lọc và tìm',
  TH_FEEDBACK: 'Phản hồi',
  TH_FULL_NAME: 'Họ và tên',
  TH_EMAIL: 'Email',
  TH_CONTENT: 'Nội dung',
  TH_LAST_UPDATE: 'Cập nhật lần cuối',
  TH_STATUS: 'Trạng thái',
  TH_ACTION: 'Thao tác',

  TITLE_REP_COMMENT: 'Trả lời bình luận',
  BUTTON_LOAD_MORE_COMMENT: 'Tải thêm phản hồi',
  BUTTON_VIEW: 'Xem thêm',
  BUTTON_HIDE: 'Ẩn',

  POPUP_CONFIRM_BROWSER_COMMENT_TITLE: 'Xác nhận hành động',

  POPUP_CONFIRM_BROWSER_RELIES_MESSAGE: 'Bạn có chắc chắn duyệt phản hồi này?',
  MSG_BROWSER_COMMNET_SUCCESS: 'Duyệt phản hồi thành công',

  POPUP_CONFIRM_DELETE_RELIES_MESSSAGE: 'Bạn có chắc chắn xoá phản hồi này?',
  MSG_DELETE_REPLIES_SUCCESS: 'Xoá phản hồi thành công',

  POPUP_CONFIRM_BROWSER_COMMENT_MESSAGE: 'Bạn có chắc chắn duyệt bình luận này?',
  MSG_BROWSER_COMMENT_SUCCESS: 'Duyệt thảo luận thành công',

  POPUP_CONFIRM_BROWSER_ALL_COMMENT_MESSAGE: 'Bạn có chắc chắn duyệt các bình luận này?',
  MSG_BROWSER_ALL_COMMENT_SUCCESS: 'Duyệt tất cả thảo luận thành công',

  POPUP_CONFIRM_DELETE_COMMENT_MESSAGE: 'Bạn có chắc chắn xoá bình luận này?',
  MSG_DELETE_COMMENT_SUCCESS: 'Xoá thảo luận thành công',

  POPUP_CONFIRM_DELETE_ALL_COMMENT_MESSAGE: 'Bạn có chắc chắn xoá các bình luận này?',
  MSG_DELETE_ALL_COMMENT_SUCCESS: 'Xoá tất cả thảo luận thành công',

  MSG_REPLIES_LOADING: 'Đang tải phản hồi...',

  MSG_GETTING: 'Đang tiếp nhận...',
  MSG_SUCCESS: 'Thành công!',
}
//BASE_PDF_QUESTION_RENDERER
const BASE_PDF_QUESTION_RENDERRE = {
  LABEL_TIME: 'Thời gian làm bài',
  TEXT_MINUTE: 'phút',
  LABEL_THREADS: 'Đề bài',
  LABEL_QUESTION: 'Câu',
  LABEL_ANSWER_THE_QUESTION: 'Trả lời',

  CHECKBOX_PICK_ONCE_CORRECT_ANSWER: '(Chọn 1 đáp án đúng)',
  CHECKBOX_PICK_MULTIPLE_ANSWERS: '(Chọn nhiều đáp án đúng)',
  CHECKBOX_FILL_BLANK: '(Điền vào chỗ trống)',
}
const SECTION_REPORT = {
  // EXAM
  // VIDEO
  // PDF
  // HOMEWORK
  HOMEWORK: {
    POPUP_CORRECTION: {
      BTN_RETURN: 'Quay lại',

      PROGRESS_BAR_ENTITY: 'bài chữa',
      PROGRESS_BAR_MSG_LOADING: 'Đang lưu bài chữa...',
      PROGRESS_BAR_MSG_ERROR:
        'Hiện chưa thể lưu được bài chữa. Đường truyền mạng của bạn đang gặp vấn đề. Hãy thử tải lại trang!',
      PROGRESS_BAR_MSG_SUCCESS: 'Lưu bài chữa thành công!',
      SECTION_EDITOR: {
        MSG_BACKGROUND_LOADING: 'Đang tải dữ liệu',
        MSG_BACKGROUND_ERROR_TITLE: 'Lỗi tải ảnh',
        MSG_BACKGROUND_ERROR_DESCRIPTION:
          'Hệ thống hiện không tải được ảnh, điều này có thể do cache trên máy bạn. Hãy xoá cache & tải lại trang HOẶC sử dụng trình duyệt ẩn danh. Trong trường hợp tình trạng này vẫn tái diễn, hãy liên hệ với kỹ thuật viên hỗ trợ.',
        TEXT_MARKER_CONTENT: 'Nội dung văn bản',
        POPUP_TEXT_EDIT: {
          TITLE_CREATE: 'Tạo văn bản',
          TITLE_EDIT: 'Sửa văn bản',
          CONTENT_PLACEHOLDER: 'Nhập nội dung văn bản vào đây',
          MSG_CONTENT_VALIDATOR: 'Vui lòng nhập nội dung văn bản',
        },
        BTN_ACTION_UNDO: 'Thao tác trước',
        BTN_ACTION_REDO: 'Thao tác vừa huỷ',
        BTN_ACTION_MOVE: 'Di chuyển',
        BTN_ACTION_DRAW: 'Vẽ lên ảnh',
        BTN_ACTION_TEXT: 'Văn bản',
        BTN_ACTION_ERASER: 'Tẩy',
        BTN_ACTION_ROTATE: 'Xoay',
        ACTION_OPTIONS: {
          ROTATE_CLOCKWISE: 'Xoay 90 độ xuôi chiều kim đồng hồ',
          ROTATE_COUNTER_CLOCKWISE: 'Xoay 90 độ ngược chiều kim đồng hồ',
        },
      },
      SECTION_APPROVE: {
        SUBSECTION_ID_TITLE: 'Mã bài nộp',
        SUBSECTION_MARK_TITLE: 'Chấm bài tập',
        SUBSECTION_MARK_ACTION: 'Cho điểm',
        OPTION_CONTAIN_NOTE: 'Kèm nhận xét',
        INPUT_NOTE_LABEL: 'Nhận xét',
        INPUT_NOTE_PLACEHOLDER: 'Nhập nhận xét cho bài tập về nhà của học sinh',
        SUBSECTION_REMINDER_TITLE: 'Lưu ý',
        SUBSECTION_REMINDER_DESCRIPTION:
          'Điểm số, nhận xét và bài chữa sẽ được hiển thị cho học sinh nhìn thấy sau khi giảng viên hoàn thành thao tác hiện tại.\nCần chú ý nếu bài tập đã được chấm điểm và duyệt, học sinh sẽ không được nộp lại bài nữa.',
        BTN_APPROVE: 'Duyệt bài tập',
        BTN_DENY: 'Từ chối',
        WARNING_MARK_IS_NOT_NUMBER: 'Sai định dạng số',
        WARNING_MARK_IS_GREATER_OR_EQUAL_MIN: 'Số lớn hơn hoặc bằng {0}',
        WARNING_MARK_IS_SMALLER_OR_EQUAL_MAX: 'Số nhỏ hơn hoặc bằng {0}',
        MSG_MARK_VALIDATOR: 'Vui lòng kiểm tra lại điểm',
      },
      SECTION_DENY: {
        INPUT_NOTE_LABEL: 'Lý do từ chối',
        INPUT_NOTE_PLACEHOLDER: 'Nhập lý do từ chối',
      },
      CONFIRM_APPROVE: {
        TITLE: 'Duyệt bài tập',
        DESCRIPTION:
          'Duyệt bài tập này đồng nghĩa với việc đóng lại bài giảng vừa rồi và học viên không thể gửi bài tập cho bài giảng này lần nữa.',
      },
      CONFIRM_DENY: {
        TITLE: 'Từ chối bài tập',
        DESCRIPTION:
          'Từ chối bài tập này đồng nghĩa với việc mở lại bài giảng và học viên có quyền gửi bài tập lại lần nữa.',
      },
      CONFIRM_SAVE_BEFORE_CHANGE_FILE: {
        TITLE: 'Xác nhận lưu lại ảnh hiện tại',
        DESCRIPTION:
          'Nếu chọn "Chuyển bài, không lưu", tất cả các thao tác chữa bài, bình luận mà bạn vừa thực hiện sẽ không được lưu lại.',
        BTN_SAVE: 'Lưu lại',
        BTN_DONT_SAVE: 'Chuyển bài, không lưu',
      },
      CONFIRM_SAVE_BEFORE_LEAVE: {
        TITLE: 'Bạn có muốn lưu lại thay đổi?',
        DESCRIPTION:
          'Nếu chọn “Thoát, không lưu”, tất cả các thao tác chữa bài , cho điểm hay nhận xét mà bạn vừa thực hiện sẽ không được lưu lại.',
        BTN_SAVE: 'Lưu lại',
        BTN_DONT_SAVE: 'Thoát, không lưu',
      },
    },
    EXCEL_EXPORT: {
      PREFIX_FILE_NAME: 'ket-qua',
      COLUMN_HEADER: {
        SUBMISSION_ID: 'Mã nộp bài',
        STUDENT_NAME: 'Học viên',
        STUDENT_EMAIL: 'Email',
        SUBMISSION_STATUS: 'Trạng thái',
        SUBMIT_TIME: 'Thời gian',
        SUBMISSION_MARK: 'Điểm',
        SUBMISSION_TITLE: 'Tên tệp bài tập',
      },
      SUBMISSION_STATUS: {
        APPROVED: 'Đã được duyệt',
        DENIED: 'Đã bị từ chối',
        SUBMITTED: 'Đã nộp bài',
        NONE: 'Chưa xem bài giảng',
      },
      SUBMISSION_MARK: {
        DENIED: 'Bài bị từ chối',
        NONE: 'Chưa có điểm',
      },
    },
  },
}
const POPUP_EDIT_PART_DIALOG = {
  TITLE_EDIT_OR_DELETE: 'Sửa hoặc xoá phần',
  TITLE_ADD_NEW: 'Thêm phần mới',
  LABEL_HEADER: 'Tiêu đề',
  PLACEHOLDER_INPUT_HEADER: 'Nhập tiêu đề cho phần câu hỏi',
  LABEL_DESCRIPTION: 'Mô tả',
  POPUP_CONFIRM_DELETE_TITLE: 'Xoá phần',
  POPUP_CONFIRM_DELETE_MESSAGE:
    'Xóa phần câu hỏi sẽ xóa toàn bộ câu hỏi / nhóm câu hỏi trong phần và không thể hoàn tác. Bạn có chắc chắn muốn xóa phần này ?',
}
const COURSE_PROGRESS_PDF_TABLE = {
  TITLE: 'Bài giảng pdf',
  TH_NAME: 'Tên bài',
  TH_TIME: 'Thời gian hoàn thành',
}
const COURSE_PROGRESS_VIDEO_TABLE = {
  TITLE: 'Bài giảng video',
  TH_NAME: 'Tên bài',
  TH_TIME: 'Thời gian hoàn thành',
}
const ONLINE_COURSE_WITH_TOPIC_MOBIE = {
  TITLE: 'Thông tin thẻ khoá học',
  LABEL_TOPIC: 'Chủ đề',
  PLACEHOLDER_SELECT_TOPIC: 'Chọn chủ đề',
  LABEL_HEADER_CARD: 'Tiều đề thẻ',
  PLACEHOLDER_INPUT_HEADER_CARD: 'Nhập tiêu đề thẻ',
  LABEL_BUTTON_DISPLAY: 'Nút hiển thị',
  PLACHOLDER_BUTTON_INFO_DISPLAY: 'Thông tin hiển thị trên nút',
  LABEL_LINK: 'Đường dẫn',
  PLACEHOLDER_LINK: 'VD: https://example.com',
}
const BASE_UPLOAD_DRAGGER = {
  MSG_UPLOAD_LOADING: 'Đã tải lên, video đang được chuẩn bị',
  MSG_UPLOAD_SUCCESS: 'Tải lên thành công',
  MSG_FILE_UPLOAD_OVER_THE_SIZE:
    'Tệp tin mà bạn tải lên đã vượt quá dung lượng cho phép. Vui lòng kiểm tra lại tệp tin mà bạn tải lên!',
  MSG_FORMAT_FILE_UPLOAD_FALSE:
    'Tệp tin mà bạn tải lên đã sai định dạng. Vui lòng kiểm tra lại tệp tin mà bạn tải lên.',
  MSG_MAX_FILES_REACHED: 'Bạn chỉ được tải lên {0} tệp tin',
}
const BASE_UPLOAD_IMAGE = {
  BUTTON_SELECT_FROM_LIBRARY: 'Chọn từ thư viện',
  BUTTON_UPLOAD_IMAGE: 'Tải ảnh lên',
  MODAL_LIBRARY: {
    TITLE: 'Chọn từ thư viện',
    SELECT_FILE_SUCCESS: 'Chọn file thành công',
    LABEL_FILE_NAME: 'Tên tệp',
    PLACEHOLDER_FILTER_FILE_NAME: 'Tìm kiếm theo tên tệp',
    LIST_IMAGE_THUMBNAIL: {
      TITLE_NOT_FOUND_IMAGE: 'Không tìm thấy tệp ảnh !',
      LABEL_PAGE: 'Trang',
    },
    ATTACHMENT_DETAIL: {
      TITLE: 'Chi tiết file',
      LABEL_SIZE: 'Kích thước',
      LABEL_CREATE_AT: 'Tạo ngày',
      BUTTON_EDIT_IMAGE_INFO: 'Sửa thông tin ảnh',
      LABEL_FORMAT: 'Định dạng',
      PLACEHOLDER_INPUT_LINK: 'Nhập đường dẫn',
      LABEL_HEADER: 'Tiều đề',
      PLACEHOLDER_INPUT_HEADER: 'Nhập tiêu đề',
    },
    BUTTON_SELECT: 'Chọn',
  },
}

const COURSE_DUPLICATE = {
  ACTION_DUPLICATE: 'Tạo bản sao',
  DUPLICATE_POPUP: {
    TITLE: 'Tạo bản sao khoá học',
    COURSE_TITLE_PREFIX: 'Bản sao của {0}',
    MSG_INVALID_COURSE_TITLE: 'Tiêu đề khoá học đã được sử dụng. Vui lòng sử dụng một tiêu đề khác.',
  },
  MSG_PROGRESS: {
    DUPLICATING_COURSE: 'Đang sao chép khoá học...',
    DUPLICATING_COURSE_CONTENT: 'Đang sao chép nội dung học...',
    DUPLICATING_COURSE_CONTENT_CHAPTER: 'Đang sao chép nội dung chương "{0}"',
    DUPLICATING_DONE: 'Hoàn thành sao chép khoá học. Đang chuyển hướng tới khoá học mới tạo...',
  },
}

export default {
  COURSE_LIST,
  FORM_CREATE_NEW_COURSE,
  COURSE_OVERVIEW,
  ONLINE_COURSE_DETAIL,
  OFFLINE_COURSE_DETAIL,
  ONLINE_COURSE_CONTENT,
  COURSE_UNIT_RESOURCE_SELECTION,
  COURSE_PROGRESS,
  COURSE_DICUSION,
  BASE_PDF_QUESTION_RENDERRE,
  POPUP_EDIT_PART_DIALOG,
  COURSE_PROGRESS_PDF_TABLE,
  COURSE_PROGRESS_VIDEO_TABLE,
  ONLINE_COURSE_WITH_TOPIC_MOBIE,
  BASE_UPLOAD_DRAGGER,
  BASE_UPLOAD_IMAGE,
  ONLINE_COURSE_OVERVIEW,
  COURSE_SECTION_REPORT,
  COURSE_REPORT_EXPORT_EXAM,
  COURSE_PROGRESS_DETAIL,
  SECTION_REPORT,
  COURSE_DUPLICATE,
  //TITLE
  TITLE_EXAM: 'Bài kiểm tra',
  TITLE_THREADS: 'Đề bài',
  TITLE_EXERCISE: 'Bài tập',
  TITLE_ACTION: 'Thao tác',
  TITLE_ANWSER_OF_STUDENT: 'Đáp án của học sinh',
  TITLE_OPERATION: 'Thao tác',
  TITLE_POST_UNIT: 'Đăng bài giảng',
  //LABEL
  LABEL_SELECT_FILE: 'Chọn file',
  LABEL_QUESTION: 'Câu hỏi',
  LABEL_QUESTION_TYPE: 'Loại câu hỏi',
  LABEL_QUESTION_GROUP: 'Nhóm câu hỏi',
  LABEL_SENTENCE: 'Câu',
  LABEL_MARK_UNIT: 'điểm',
  LABEL_ANWSER: 'Câu trả lời',
  LABEL_ANWSER_TRUE: 'Đáp án',
  LABEL_CURRENT_UNIT: 'VNĐ',
  OPTION_ALL: 'Tất cả',
  PLACEHOLDER_INPUT_FILL_BLANK: 'Nhập câu trả lời',
  //TEXT
  TEXT_UPDATING: 'Đang cập nhật',
  TEXT_NOT_MARK: 'Chưa chấm điểm',
  //BUTTON
  BUTTON_CONFIRM: 'Xác nhận',
  BUTTON_CANCEL_TEXT: 'Huỷ',
  BUTTON_CANCEL: 'Huỷ bỏ',
  BUTTON_SAVE: 'Lưu',
  BUTTON_OK: 'OK',
  BUTTON_DELETE: 'Xoá bài',
  BUTTON_VIEW_FILTER: 'Lọc và xem',
  BUTTON_SEARCH_FILTER: 'Lọc và tìm',
  BUTTON_REFUSE: 'Từ chối',
  BUTTON_DOWNLOAD: 'Tải xuống',
  BUTTON_DOWNLOAD_ALL: 'Tải tất cả',
  BUTTON_ACCEPT: 'Duyệt',
  BUTTON_BACK: 'Trở lại',
  BUTTON_RELOAD: 'Tải lại',
  BUTTON_CLOSE: 'Đóng',
  BUTTON_CREATE: 'Tạo',
  BUTTON_ADD: 'Thêm',
  BUTTON_EDIT: 'Chỉnh sửa',
  BUTTON_SEARCH: 'Tìm kiếm',
  BUTTON_DELETE_ANWSER: 'Xoá câu hỏi',
  BUTTON_MARK: 'Chấm điểm',
  BUTTON_DONATE_MARK: 'Cho điểm',
  BUTTON_VIEW_DOWNLOAD: 'Xem và tải về',
  BUTTON_EXPORT_EXCEL: 'Xuất Excel',
  BUTTON_INPUT: 'Nhập',
  BUTTON_PREVIEW: 'Xem trước',
  //MESSAGE
  MSG_DATA_LOADING: 'Đang tải dữ liệu...',
  MSG_UPDATING_DATA: 'Đang cập nhật dữ liệu...',
  MSG_UPDATE_DATA_SUCCESS: 'Cập nhật dữ liệu thành công!',
  MSG_UPDATING: 'Đang cập nhật...',
  MSG_UPDATE_SUCCESS: 'Cập nhật thành công!',
  MSG_UPDATE_FAIL: 'Cập nhật thất bại!',
  MSG_DELETE_SUCCESS: 'Xoá thành công!',
  MSG_DELETE_FAIL: 'Xoá thất bại',
  MSG_CHECK_GROUP_LEARNING_AGAIN: 'Vui lòng chọn nhóm học',
  MSG_GETTING_DATA: 'Đang lấy dữ liệu...',
  MSG_REFUSE_SUCCESS: 'Từ chối thành công!',
  MSG_COPY_SUCCESS: 'Sao chép thành công',
  MSG_COPY_FAIL: 'Sao chép không thành công. Vui lòng thử lại',
  MSG_EXPORTING_DATA: 'Đang xuất dữ liệu...',
  //TABLE_HEADER
  TH_COURSE_NAME: 'Khoá học',
  TH_CREATEDAT: 'Thêm vào nhóm lúc',
  TH_ACTION: 'Thao tác',
  TEXT_INCOGNITO_NAME: 'Không có tên',
  TEXT_INCOGNITO_EMAIL: 'Không có email',
  TEXT_STARTED_TIME: 'Đã bắt đầu',
  TEXT_DO_NOT_EXAM: 'Chưa làm bài',
  TEXT_TIMES: 'Lần',
  TEXT_DID: 'Đã làm',
  //STATUS DO EXAM
  CHECKBOX_STATUS_DOING: 'Đang làm',
  CHECKBOX_DONE: 'Hoàn thành',
  MSG_CHECK_INFO_AGAIN: 'Vui lòng kiểm tra lại thông tin !',
  MSG_CHECK_PICK_GROUP_AGAIN: 'Vui lòng chọn nhóm học',
  //--------------------
  TOOLTIP_ONLINE_COURSE_LIST: 'Danh sách khoá học Online',
  DEFAULT_PROGRESS_BAR: 'Đang {0} {1} số {2}', // 0: action, 1: entity, 2: entity number
  BTN_VIEW_DETAIL: 'Xem chi tiết',
}
